import React, {useRef, useState} from "react";
import Slider from "react-slick";

import ArrowLeft from '../../images/arrow-left.svg'
import ArrowRight from '../../images/arrow-right.svg'

import * as S from './styled'

export type ImagesProps = {
  url: string
}

export type Images = {
  images: ImagesProps[]
  openModal: (e: string) => void
}

const NextArrow = () => {
  return (
    <S.NextArrow><img src={ArrowRight} alt=""/></S.NextArrow>
  );
}

const PrevArrow = () => {
  return (
    <S.PrevArrow><img src={ArrowLeft} alt=""/></S.PrevArrow>
  );
}

const CarouselSlider = ({images, openModal}: Images) => {

  const customeSlider = useRef();

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 500,
    cssEase: "linear",
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          cssEase: "linear",
        }
      }
    ]
  };

  const gotoNext = () => {
    // @ts-ignore
    customeSlider.current.slickNext()
  }

  const gotoPrev = () => {
    // @ts-ignore
    customeSlider.current.slickPrev()
  }

  return (
    <S.Container>
      <S.PrevArrow onClick={() => gotoPrev()}><img src={ArrowLeft} alt=""/></S.PrevArrow>
      <S.NextArrow onClick={() => gotoNext()}><img src={ArrowRight} alt=""/></S.NextArrow>
      <Slider ref={customeSlider} {...settings}>
        {images.map((img, index) => (
            <div key={index}>
              <div className="slick-space" onClick={() => openModal(img.url) } style={{ cursor: 'pointer' }}>
                <img src={img.url} alt="Modalidades" />
              </div>
            </div>
          )
        )}
      </Slider>
    </S.Container>
  );
}

export default CarouselSlider
