import React from 'react';

import * as S from './styled'
import {UserTestimonyProps} from "./";

const TestimonyItem = ({image, name, status, text}: UserTestimonyProps) => {
  return (
    <S.BoxTestimony>
      <S.UserPhoto src={image} alt={name}/>
      <S.BoxTexTestimony>
        <h1>{name}</h1>
        <h2>{status}</h2>
        <h3>{text}</h3>
      </S.BoxTexTestimony>
    </S.BoxTestimony>
  );
};

export default TestimonyItem;
