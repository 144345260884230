import React from 'react'

export type IconCheckProps = {
  color?: string
}
const IconCheck = ({color = '#00b2a9'}: IconCheckProps) => {
  return (
    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="32" height="32">
      <g id="checkmark-circle-2" fill={color}>
        <path id="Path_122" data-name="Path 122"
              d="M15.251 2A13.251 13.251 0 1 0 28.5 15.251 13.251 13.251 0 0 0 15.251 2Zm0 23.851a10.6 10.6 0 1 1 10.6-10.6 10.6 10.6 0 0 1-10.6 10.6Z"
              transform="translate(.65 .65)"/>
        <path id="Path_123" data-name="Path 123"
              d="m17.041 8.519-5.009 6.625-2.16-2.8a1.327 1.327 0 1 0-2.094 1.63L11 18.1a1.329 1.329 0 0 0 2.094-.013l6.056-7.95a1.333 1.333 0 1 0-2.12-1.617Z"
              transform="translate(2.438 2.599)"/>
      </g>
    </svg>
  );
};

export default IconCheck;
