import React from 'react'

import * as S from './styled'

import TimeLineItem from './timelineitem'
import {StaticImage} from "gatsby-plugin-image";

const WhoComponent = () => {

  const timeline = [
    {
      title: 'experiência',
      text: 'A RM SPORTS é uma Assessoria Esportiva com mais de 20 anos de experiência, desenvolvemos programas de treinamento nas diversas modalidades auxiliando no alcance dos objetivos dos nossos alunos. Atendemos todo tipo de público de várias idades e diferentes objetivos de forma segura e saudável de atletas iniciantes à avançados.'
    },
    {
      title: 'diferencial',
      text: 'Nosso diferencial maior é que trabalhamos com planilhas personalizadas e individualizadas respeitando o seu condicionamento e tempo disponível para a execução do treinamento. Além de promover treinamentos coletivos e simulados em Campinas e região. <br /><br /> Acreditamos que um treinamento realizado com planejamento extremamente individual, levando em consideração todas as características do atleta, são requisitos básicos para o sucesso no resultado, tudo isso sempre associado a nossos anos de experiência.'
    },
  ];

  return (
    <S.Wrapper>
      <S.Title id="quem-somos">Quem Somos</S.Title>
      <S.TimelineContainer>
        {timeline.map((data, idx) => (
          <TimeLineItem data={data} key={idx}/>
        ))}
      </S.TimelineContainer>
      <S.CoachContent>
        <S.CoachPicture>
          <StaticImage src="../../images/coach.png" alt="Foto de nossas Coaches" />
        </S.CoachPicture>
        <S.CoachText>
          <h2>COACH ROSANA MERINO</h2>
          <p>ROSANA MERINO, iniciou no esporte com 4 anos de idade, ex-atleta profissional de Natação, Ciclismo, Triathlon e Corrida de Aventura. Treinadora com mais de 40 anos de experiência, já revelou vários talentos no esporte em diversas modalidades. Somada a toda sua experiência esportiva, hoje ela é formada como Coaching (IBC) com 6 certificações internacionais, fazendo com que o atleta chegue mais rápida aos seus objetivos.</p>
          <h2>COACH VANESSA GIANINNI</h2>
          <p>VANESSA GIANINNI, atleta revelada pela Rosana Merino em 2001 com uma carreira brilhante no triantlon profissional com vários títulos nacionais e internacionais. Ho je auxilia a Rosana na assessoria RM Sports com seus alunos e treinos e competições.</p>
        </S.CoachText>
      </S.CoachContent>
    </S.Wrapper>
  );
};

export default WhoComponent;
