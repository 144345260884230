import styled, {css} from "styled-components"
import media, {generateMedia} from 'styled-media-query'

import HeroBG from '../../images/hero-bg.png'
import LionBG from '../../images/leao.png'

const customMedia = generateMedia({
  desktop: '1280px',
  large: '1170px'
});

export const Wrapper = styled.div`
  width: 100%;
  height: 822px;
  max-height: 822px;
  background-image: url(${HeroBG});
  background-repeat: no-repeat;
  background-size: 100% 820px;

  ${media.greaterThan("huge")`
    height: 900px;
    max-height: 900px;
    background-size: 100% 900px; 
  `}

  ${media.lessThan("large")`
    height: 605px;
    max-height: 605px;
    background-position: left top -30px;
    background-size: 100% 800px;
  `}

  ${media.lessThan("medium")`
    height: 600px;
    min-height: 600px;
    background-size: cover;
    background-position: center top 0px;
  `}

`

export const Content = styled.div`
  height: 822px;
  max-height: 822px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-image: url(${LionBG});
  background-repeat: no-repeat;
  background-size: 100% 820px;

  ${media.greaterThan("huge")`
      height: 900px;
      max-height: 900px;
      background-size: 100% 900px;  
  `}

  ${media.lessThan("large")`
    height: 605px;
    max-height: 605px;
    background-position: left top -30px;
    background-size: 100% 800px;
  `}

  ${media.lessThan("medium")`
      height: 590px;
      min-height: 590px;
      background-size: cover;
      background-position: left -230px top 22px;
  `}
`

export const ContentText = styled.div.attrs(() => ({
  className: 'container',
}))`
  max-width: 1100px !important;
  height: 710px;
  max-height: 710px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0 0 40px 0;

  a {
    text-decoration: none;
  }

  ${media.greaterThan("huge")`
      height: 795px;
      max-height: 795px;
      padding: 0 0 55px 0;
  `}

  ${customMedia.between('large', 'desktop')`
    padding-bottom: 100px;
  `}

  ${media.lessThan("large")`
    max-width: 880px!important;
    height: 520px;
    padding-bottom: 30px;
  `}

  ${media.lessThan("medium")`
      height: 450px;
      max-height: 450px;
      min-height: 450px;
      padding: 100px 16px 0!important;
      justify-content: flex-start;
  `}

`

export const Title = styled.h1`
  ${({theme}) => css`
    width: 50%;
    text-align: left;
    font-family: ${theme.font.family};
    font-weight: ${theme.font.ultra};
    font-size: ${theme.font.sizes.xxlarge};
    line-height: ${theme.font.sizes.xxlarge};
    color: ${theme.colors.white};
    text-transform: uppercase;

    ${media.lessThan("medium")`
      width: 100%;
      line-height: ${theme.font.sizes.xxlarge};
      font-size: 30px;
    `}

  `}



`

export const SubTitle = styled.h2`
  ${({theme}) => css`
    text-align: left;
    font-family: ${theme.font.family};
    font-weight: ${theme.font.ultra};
    font-size: ${theme.font.sizes.medium};
    line-height: ${theme.font.sizes.xlarge};
    letter-spacing: 0;
    color: ${theme.colors.primary};
    text-transform: uppercase;
    margin-bottom: 10px;
  `}

`

export const Description = styled.h3`
  ${({theme}) => css`
    width: 38%;
    text-align: left;
    font-family: ${theme.font.family};
    font-weight: ${theme.font.normal};
    font-size: ${theme.font.sizes.medium};
    line-height: ${theme.font.sizes.xlarge};
    letter-spacing: 0;
    color: ${theme.colors.white};
    margin-top: 10px;
    margin-bottom: 24px;

    ${media.lessThan("medium")`
      width: 99%;
      line-height: ${theme.font.sizes.xlarge};
      font-size: ${theme.font.sizes.medium};
    `}

  `}

`
