import React from 'react';

import * as S from './styled'

type SectionsProps = {
  active: boolean
  text: string
}

const Sections = ({ active, text}: SectionsProps) => {
  return (
    <S.DescriptionInternal active={active}>
      <span dangerouslySetInnerHTML={{__html: text}}/>
    </S.DescriptionInternal>
  );
};

export default Sections;
