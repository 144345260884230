import styled, {css} from "styled-components"
import media from 'styled-media-query'
import ImageStructure from "src/images/structure.png";

export const Wrapper = styled.div`
  ${({theme}) => css`
    width: 100%;
    background-color: ${theme.colors.mainBg};
    margin: 0;
    padding: 0;

    ${media.lessThan("medium")`
      padding-top: 40px;
    `}
  `}
`

export const InternalWrapper = styled.div.attrs(() => ({
  className: 'container',
}))`
  ${({theme}) => css`
    background-color: ${theme.colors.mainBg};
    max-width: 1100px !important;
    height: 760px;
    max-height: 760px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    ${media.lessThan("medium")`
      width: 96% !important;
      height: 100%;
      min-height: 1230px;
      margin: 0 auto;
    `}

    ${media.lessThan("large")`
      max-width: 880px !important;
    `}
  `}
`

export const Title = styled.div`
  ${({theme}) => css`
    width: 100%;
    font-family: ${theme.font.family};
    font-weight: ${theme.font.ultra};
    font-size: ${theme.font.sizes.huge};
    line-height: ${theme.font.sizes.xlarge};
    letter-spacing: 0;
    color: ${theme.colors.white};
    text-transform: uppercase;
    text-align: center;
    padding: 35px 0 60px;

    ${media.lessThan("medium")`
      font-size: ${theme.font.sizes.xxlarge};
    `}

  `}
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  ${media.lessThan("medium")`
     margin-bottom: 30px;
  `}
`

export const ContainerLinks = styled.div`
  ${({theme, active}) => css`
    max-width: 420px;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    ${media.lessThan("medium")`
      width: 100%!important;
    `}
    & ul {
      position: relative;
      z-index: 1;
      width: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
      text-align: left;
      font-family: ${theme.font.family};
      font-weight: ${theme.font.ultra};
      font-size: ${theme.font.sizes.medium};
      line-height: ${theme.font.sizes.xlarge};
      letter-spacing: 0;
      color: ${theme.colors.white};
      text-transform: uppercase;
    }

    ${media.lessThan("large")`
      max-width: 380px;
      width: 38%;
    `}

  `}
`
export const Links = styled.li`
  ${({theme, active}) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    border-radius: 0 10px 10px 0;
    background-color: ${active ? theme.colors.primary : theme.colors.altBg};
    margin-bottom: 24px;
    padding-left: ${theme.font.sizes.medium};

    &:after {
      position: absolute;
      z-index: 2;
      content: "";
      left: 0;
      width: 3px;
      height: 43px;
      background-color: ${active ? theme.colors.info : theme.colors.white};
      border-radius: 10px;
    }

    &:hover {
      cursor: pointer;
      background-color: ${theme.colors.primary};

      &:after {
        background-color: ${theme.colors.info};
      }
    }

    ${media.lessThan("large")`
      font-size: ${theme.font.sizes.small};
    `}

  `}
`

export const Description = styled.div`
  ${({theme}) => css`
    width: 60%;
    max-width: 639px;
    height: calc(100% - 20px);
    border-radius: 10px;
    background-color: ${theme.colors.altBg};
    padding: ${theme.spacings.small};
    font-family: ${theme.font.family};
    font-weight: ${theme.font.bold};
    font-size: ${theme.font.sizes.medium};
    letter-spacing: 0;
    color: ${theme.colors.white};

    ${media.lessThan("medium")`
      display: none;
    `}

  `}
`

export type DescriptionProps = {
  active: boolean
}

export const DescriptionInternal = styled.div<DescriptionProps>`
  ${({theme, active}) => css`
    width: 100%;
    height: 430px;
    display: ${active ? 'block' : 'none'};
    border-radius: 10px;
    background-color: ${theme.colors.altBg};
    padding: ${theme.spacings.small};
    font-family: ${theme.font.family};
    font-weight: ${theme.font.bold};
    font-size: ${theme.font.sizes.medium};
    letter-spacing: 0;
    color: ${theme.colors.white};
    margin-bottom: 30px;
    text-transform: none;

    ${media.greaterThan("medium")`
      display: none;
    `}

  `}
`

export const ContentImages = styled.div.attrs(() => ({
  className: 'container',
}))`
  ${({theme}) => css`
    max-width: 1100px !important;
    height: 710px;
    max-height: 710px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 0 !important;
    margin-top: ${theme.spacings.xsmall};

    button {
      display: none !important;
    }

  `}
`

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 101% !important;
  height: auto;
  min-height: 210px;
  overflow: hidden !important;

  .slick-list {
    width: 309px !important;
    height: 198px;

    ${media.lessThan("medium")`
        min-width: 330px;
    `}
  }

  .slick-space {
    text-align: center;
    min-width: 309px;
    max-width: 335px !important;
    height: inherit;

    ${media.lessThan("medium")`
        min-width: 332px;
    `}
    & img {
      width: 100%;
      min-width: 310px !important;
      max-width: 310px !important;
      height: 198px;
      border-radius: 10px;
      object-fit: cover;
      object-position: center top;

      ${media.lessThan("medium")`
        min-width: 330px !important;
        max-width: 330px !important;
      `}
    }
  }

  .slick-slide {
    width: 335px !important;
    padding-right: 30px;

    transition: all ease 0.3s;

    ${media.lessThan("medium")`
       width: 390px !important;
       padding-right: 20px;
    `}
  }

  .slick-slide.slick-current {
    /* padding-right: 0px;
    padding-left: 0px; */
  }

  .slick-list,
  .slick-slider,
  .slick-track {
    position: absolute;
    width: 100% !important;
    display: flex;
  }
`

export type BoxImageProps = {
  image: string
  modal: boolean
}

export const BoxImage = styled.div<BoxImageProps>`
  ${({ image, modal }) => css`
    width: 100%;
    height: 100%;
    display: ${modal ? 'block' : 'none'};
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background:  url(${image}), linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    &:hover {
      cursor: pointer;
    }
    
    & > img {
      min-height: 95%!important;
    }
  `}
`

export const NextArrow = styled.div`
  ${({theme}) => css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    z-index: 90;
    top: 0;
    right: -1px;
    width: 80px;
    height: 200px;
    border: none;
    background: linear-gradient(to right, transparent 0%, ${theme.colors.mainBg} 90%);
    pointer-events: all;

    & img {
      margin-right: -10px;

      &:hover {
        cursor: pointer;
      }
    }
  `}
`

export const PrevArrow = styled.div`
  ${({theme}) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    z-index: 90;
    top: 0;
    left: -1px;
    width: 80px;
    height: 200px;
    border: none;
    background: linear-gradient(to left, transparent 0%, ${theme.colors.mainBg} 90%);
    pointer-events: all;

    & img {
      margin-left: -10px;

      &:hover {
        cursor: pointer;
      }
    }
  `}
`
