import React, {useState} from 'react';

const IconInsta = () => {
  const [color, setColor] = useState('#fff');
  return (
    <svg id="Component_6_1" data-name="Component 6 – 1" xmlns="http://www.w3.org/2000/svg" width="30.048"
         height="30.048" viewBox="0 0 30.048 30.048" onMouseOver={() => setColor('#00B2A9')}
         onMouseOut={() => setColor('#fff')}>
      <path id="Path_105" data-name="Path 105"
            d="M17.024,13.3a3.726,3.726,0,1,0,3.726,3.726A3.726,3.726,0,0,0,17.024,13.3ZM31.943,9.617a9.811,9.811,0,0,0-.631-3.4,6.01,6.01,0,0,0-3.486-3.486,9.811,9.811,0,0,0-3.4-.631C22.493,2,21.922,2,17.024,2s-5.469,0-7.407.105a9.811,9.811,0,0,0-3.4.631A6.01,6.01,0,0,0,2.736,6.222a9.811,9.811,0,0,0-.631,3.4C2,11.555,2,12.126,2,17.024s0,5.469.105,7.407a10.306,10.306,0,0,0,.631,3.41,5.919,5.919,0,0,0,1.367,2.1,5.844,5.844,0,0,0,2.118,1.367,9.811,9.811,0,0,0,3.4.631c1.938.105,2.509.105,7.407.105s5.469,0,7.407-.105a9.811,9.811,0,0,0,3.4-.631,5.844,5.844,0,0,0,2.118-1.367,5.919,5.919,0,0,0,1.367-2.1,9.916,9.916,0,0,0,.631-3.41c.105-1.938.105-2.509.105-7.407S32.048,11.555,31.943,9.617ZM28.127,21.636a8.609,8.609,0,0,1-.586,2.7,5.8,5.8,0,0,1-3.2,3.2,8.609,8.609,0,0,1-2.719.526H12.427a8.609,8.609,0,0,1-2.719-.526,5.593,5.593,0,0,1-3.2-3.2A8.248,8.248,0,0,1,6,21.621c0-1.187,0-1.5,0-4.6v-4.6a8.248,8.248,0,0,1,.511-2.719A5.273,5.273,0,0,1,7.8,7.739,5.394,5.394,0,0,1,9.707,6.507a8.609,8.609,0,0,1,2.719-.526h9.195a8.609,8.609,0,0,1,2.719.526,5.593,5.593,0,0,1,3.2,3.2,8.609,8.609,0,0,1,.526,2.719c0,1.187,0,1.5,0,4.6s.105,3.41.06,4.6Zm-2.4-11.178A3.576,3.576,0,0,0,23.6,8.34a6.01,6.01,0,0,0-2.073-.331H12.517a6.01,6.01,0,0,0-2.073.391,3.576,3.576,0,0,0-2.118,2.043,6.415,6.415,0,0,0-.316,2.073v9.014A6.415,6.415,0,0,0,8.4,23.6a3.576,3.576,0,0,0,2.118,2.118,6.415,6.415,0,0,0,2,.391h9.014a6.01,6.01,0,0,0,2.073-.391A3.576,3.576,0,0,0,25.723,23.6a6.01,6.01,0,0,0,.391-2.073V12.517a5.679,5.679,0,0,0-.391-2.073Zm-8.7,12.3A5.724,5.724,0,0,1,11.3,17.024h0a5.739,5.739,0,1,1,5.724,5.739Zm6.01-10.351a1.352,1.352,0,0,1,0-2.689h0a1.352,1.352,0,0,1,0,2.689Z"
            transform="translate(-2 -2)" fill={color}/>
    </svg>

  );
};

export default IconInsta;
