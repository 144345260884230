import React from 'react'
import {Link} from 'gatsby'

import IconSpotify from '../../images/icon-spotify.png'
import IconSeta from '../../images/icon-seta.svg'

import Audio1 from '../../images/thumb-audio1.png'
import Audio2 from '../../images/thumb-audio2.png'
import Audio3 from '../../images/thumb-audio3.png'
import Video1 from '../../images/thumb-video1.png'
import Video2 from '../../images/thumb-video2.png'
import Video3 from '../../images/thumb-video3.png'

import * as S from './styled'

const PhraseComponent = () => {

  const images = [
    {
      url: Audio1,
      icon: IconSpotify,
      link: 'https://endorfinabr.com/episode/episodio-75-com-rosana-merino/?utm_source=rss&utm_medium=rss&utm_campaign=episodio-75-com-rosana-merino'
    },
    {
      url: Audio2,
      icon: IconSpotify,
      link: 'https://open.spotify.com/episode/16K6pKtCWA3yJ3E22BNPOB?si=a7K9dT7LTpW9t_gQFUixrA'
    },
    {
      url: Audio3,
      icon: IconSpotify,
      link: 'https://www.porfalaremcorrer.com/pfc-218-rosana-merino/'
    },
    {
      url: Video1,
      icon: IconSeta,
      link: 'https://gshow.globo.com/EPTV/Mais-Caminhos/noticia/2014/11/no-quadro-vida-que-inspira-pedro-leonardo-traz-uma-historia-emocionante.html'
    },
    {
      url: Video2,
      icon: IconSeta,
      link: 'https://www.youtube.com/watch?v=AvFe8AKL7rQ'
    },
    {
      url: Video3,
      icon: IconSeta,
      link: 'https://www.youtube.com/watch?v=dHG7V0YqaaA'
    },
  ]

  return (
    <S.Wrapper id="coach">
      <S.Content>
        <S.Phrase>Escalar sempre é difícil, a tragetória é aprendizado, mas a visão do topo é indescritível. Escale
          todas as suas montanhas.</S.Phrase>
        <S.Author>Rosana Merino</S.Author>
        <S.MoreContent>
          <h2>CONHEÇA MAIS AS COACHES</h2>
          <S.Midia>
            {images.map((item, index) => {
              return (<S.Images key={index}>
                <Link to={item.link} target="_blank">
                  <div>
                    <img src={item.icon} alt=""/>
                  </div>
                  <img src={item.url} alt=""/>
                </Link>
              </S.Images>)
            })}
          </S.Midia>
        </S.MoreContent>
      </S.Content>
    </S.Wrapper>
  );
};

export default PhraseComponent;
