import styled, {css} from "styled-components"
import media, {generateMedia} from 'styled-media-query'
import {StaticImage} from "gatsby-plugin-image";

import ArcoInferior from '../../images/arco-inferior.svg'
import ImageStructure from '../../images/structure.png'

import Arrow from '../../images/arrow-plans.svg'

const customMedia = generateMedia({
  iphone: '391px',
});

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  min-height: 900px;

  ${media.lessThan("large")`
     min-height: inherit;      
  `}

`

export type WrapperModalProps = {
  modal: boolean
}

export const WrapperModal = styled.div<WrapperModalProps>`
  ${({modal}) => css`
    width: 100%;
    height: 100%;
    display: ${modal ? 'block' : 'none'};
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    text-align: center;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.6);

    &:hover {
      cursor: pointer;
    }

    img {
      padding-top: 10vh;
      width: auto;
      height: 90vh;
    }
    
     ${media.lessThan("medium")`
        img {
          padding-top: 100px;
          width: 100%;
          height: 100%;
        }
     `}
  `}
`

export const WrapperArcos = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  min-height: inherit;
  background-image: url(${ArcoInferior});
  background-repeat: no-repeat;
  background-position: top 0 center;
  background-size: 110%;

  ${media.lessThan("large")`
      background-position: top 0 center;
  `}
`

export const Content = styled.div.attrs(() => ({
  className: 'container',
}))`
  max-width: 1100px !important;
  height: 100%;
  min-height: inherit;
  margin-top: -125px;
  display: grid;
  grid-template-areas:
    "textUniform slideUniform"
    "slideStructure textStructure";
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 50% 50%;
  row-gap: 60px;

  ${media.lessThan("large")`
      max-width: 880px !important;
      margin-top: -140px;
  `}

  ${media.lessThan("medium")`
      flex-direction: column;
      max-width: 95% !important;
      margin-top: 0px;
      grid-template-areas:
        "textUniform"
        "slideUniform"
        "textStructure"
        "slideStructure";
      grid-template-rows: 1fr;
      grid-template-columns: 100%;
      row-gap: 20px;
  `}
`

export const ColumnTextUniform = styled.div`
  height: 100%;
  min-height: 510px;
  grid-area: textUniform;

  ${media.lessThan("large")`
      min-height: 480px;
      margin-bottom: 0;
  `}

  ${media.lessThan("medium")`
      width: 100%;
      min-height: 160px;
      
  `}

`

export const ColumnSlideUniform = styled.div`
  height: 100%;
  min-height: 510px;
  grid-area: slideUniform;

  ${media.lessThan("large")`
      min-height: 480px;
      margin-bottom: 0;
  `}

  ${media.lessThan("medium")`
      width: 100%;
      min-height: 180px;
      
      &:nth-child(4) {
        border: 1px solid yellow;
      }
  `}
`

export const ColumnSlideStructure = styled.div`
  height: 100%;
  min-height: 510px;
  margin-bottom: 40px;
  grid-area: slideStructure;

  ${media.lessThan("large")`
      min-height: 480px;
      margin-bottom: 0;
  `}

  ${media.lessThan("medium")`
      width: 100%;
      min-height: 160px;
      
  `}

`

export const ColumnTextStructure = styled.div`
  height: 100%;
  min-height: 510px;
  margin-bottom: 50px;
  grid-area: textStructure;

  ${media.lessThan("large")`
      min-height: 480px;
      margin-bottom: 0;
  `}

  ${media.lessThan("medium")`
      width: 100%;
      min-height: 180px;
  `}
`

export const ArrowUniform = styled.div`
  position: absolute;
  right: 40px;
  top: 165px;
  width: 162px;
  height: 94px;
  background-image: url(${Arrow});
  background-repeat: no-repeat;
  transform: rotate(360deg);

  ${media.lessThan("medium")`
      display: none;
  `}
`;

export const BoxUniform = styled.div`
  ${({theme}) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 530px;
    min-height: 555px !important;
    background-color: ${theme.colors.altBg};
    border: 1px solid ${theme.colors.gray};
    padding: 10px;
    border-radius: 70px;

    & h1 {
      font-family: ${theme.font.family};
      font-weight: ${theme.font.top};
      font-size: ${theme.font.sizes.huge};
      line-height: ${theme.font.sizes.xxlarge};
      text-transform: uppercase;
      margin-top: 40px;
      margin-bottom: 10px;
    }

    & h2 {
      font-family: ${theme.font.family};
      font-weight: ${theme.font.top};
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.primary};
      text-align: center;
    }

    ${media.lessThan("large")`
        padding: 20px;
        min-height: 315px;
    `}

  `}

`

export const BoxTextUniform = styled.div`
  ${({theme}) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 55px;

    & h1 {
      font-family: ${theme.font.family};
      font-weight: ${theme.font.ultra};
      font-size: ${theme.font.sizes.xxlarge};
      line-height: ${theme.font.sizes.xxlarge};
      margin-bottom: 17px;
    }

    & h2 {
      font-family: ${theme.font.family};
      font-weight: ${theme.font.normal};
      font-size: ${theme.font.sizes.medium};
      text-align: center;
    }

    ${media.lessThan("medium")`
    margin-bottom: 20px;
      & h1 {
        width: 70%;
        line-height: 4.5rem;
        text-align: center;
      }  
      & h2 {
        width: 80%;
        text-align: center;
      }    
    `}
  `}
`

export const BoxStructure = styled.div`
  ${({theme}) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 530px;
    min-height: 555px !important;
    background-color: ${theme.colors.altBg};
    border: 1px solid ${theme.colors.gray};
    padding: 0;
    border-radius: 70px;

    & h1 {
      font-family: ${theme.font.family};
      font-weight: ${theme.font.top};
      font-size: ${theme.font.sizes.huge};
      line-height: ${theme.font.sizes.xxlarge};
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    & h2 {
      font-family: ${theme.font.family};
      font-weight: ${theme.font.top};
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.primary};
      text-align: center;
    }

  `}

`

export const BoxImageStructure = styled.div`
  width: 100%;
  height: 75%;
  border-radius: 70px;
  background-size: 100%;
  margin-bottom: 4rem;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }
`

export const BoxTextStructure = styled.div`
  ${({theme}) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 80px;
    padding-left: 25px;

    & h1 {
      font-family: ${theme.font.family};
      font-weight: ${theme.font.ultra};
      font-size: ${theme.font.sizes.xxlarge};
      line-height: ${theme.font.sizes.xxlarge};
      margin-bottom: 25px;
    }

    & h2 {
      font-family: ${theme.font.family};
      font-weight: ${theme.font.normal};
      font-size: ${theme.font.sizes.medium};
      text-align: left;
    }

    ${media.lessThan("medium")`
      padding-top: 20px;
    `}
  `}
`

export const ContentImages = styled.div.attrs(() => ({
  className: 'container',
}))`
  ${({theme}) => css`
    width: 100%;
    height: 410px;
    max-height: 410px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 0 !important;
    padding-top: ${theme.spacings.medium};

    img {
      margin-bottom: 30px;
    }

    button {
      display: none !important;
    }

    ${media.lessThan("large")`
       max-height: 300px;
    `}

  `}
`

export const ArrowStructure = styled.div`
  position: absolute;
  left: 40px;
  bottom: -110px;
  width: 162px;
  height: 94px;
  background-image: url(${Arrow});
  background-repeat: no-repeat;
  transform: rotate(-360deg) scaleX(-1);

  ${media.lessThan("medium")`
      display: none;
  `}

`;

export const NextArrow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  z-index: 90;
  top: 0;
  right: 0;
  width: 80px;
  height: 350px;
  border: none;
  pointer-events: all;

  & img {
    margin-right: -10px;

    &:hover {
      cursor: pointer;
    }
  }

  ${media.lessThan("large")`
     height: 280px;
  `}

`

export const PrevArrow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  z-index: 90;
  top: 0;
  left: 0;
  width: 80px;
  height: 350px;
  border: none;
  pointer-events: all;

  & img {
    margin-left: -10px;

    &:hover {
      cursor: pointer;
    }
  }

  ${media.lessThan("large")`
     height: 280px;
  `}
`

export const ContainerCarousel = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 101% !important;
  height: auto;
  min-height: 360px;
  overflow: hidden !important;

  ${media.lessThan("medium")`
     min-height: 290px !important;
  `}
  .slick-list {
    width: 309px !important;
    height: 198px;

    ${media.lessThan("medium")`
       width: 270px !important;
       height: 220px !important;
    `}
  }

  .slick-space {
    text-align: center;
    min-width: 309px;
    max-width: 480px !important;
    height: inherit;

    & img {
      width: 100% !important;
      min-width: 475px !important;
      max-width: 480px !important;
      height: 360px;
      margin: 0 -3px;

    }
  }

  .slick-slide {
    width: 480px !important;
    padding-right: 30px;

    transition: all ease 0.3s;
  }

  .slick-slide.slick-current {
    /* padding-right: 0px;
    padding-left: 0px; */
  }

  .slick-list,
  .slick-slider,
  .slick-track {
    position: absolute;
    width: 100% !important;
    display: flex;
  }

  ${media.lessThan("large")`
      min-height: 280px;
     
      .slick-slide {
        width: 400px !important;
        padding: 0 25px;
    
        transition: all ease 0.3s;
      }

     
     .slick-space {
     
        & img {
          width: 100% !important;
          min-width: 335px !important;
          max-width: 335px !important;
          height: 290px;
          margin: 0;
        }
     }
  `}

  ${media.lessThan("medium")`
      min-height: 280px;
      
      .slick-slide {
        width: 360px !important;
        min-width: 360px !important;
        padding: 0;
        margin-left: -20px !important;
    
        transition: all ease 0.3s;
      }
      
      .slick-space {
     
        & img {
          width: 100% !important;
          min-width: 310px !important;
          max-width: 330px !important;
          height: 290px;
          margin: 0;
        }
     }
  `}

  ${customMedia.lessThan("iphone")`
      min-height: 280px;
      
      .slick-slide {
        width: 350px !important;
        min-width: 350px !important;
        padding: 0;
        margin-left: -30px !important;
    
        transition: all ease 0.3s;
      }
      
      .slick-space {
     
        & img {
          width: 100% !important;
          min-width: 330px !important;
          max-width: 330px !important;
          height: 290px;
          margin: 0;
        }
     }
  `}
`

export const ContainerTestimony = styled.div.attrs(() => ({
  className: 'container',
}))`
  ${({theme}) => css`
    width: 100%;
    height: 280px;
    max-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 0 !important;
    margin-top: ${theme.spacings.xxlarge};
    margin-bottom: ${theme.spacings.xxlarge};

    button {
      display: none !important;
    }

    ${media.lessThan("large")`
       max-height: 300px;
    `}

    ${media.lessThan("medium")`
        height: 410px;
        max-height: 420px;
        margin-top: 60px !important;
    `}

  `}

`

export const CarouselTestimonyWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100% !important;
  height: inherit;
  overflow: hidden !important;
`

export const CarouselTestimony = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 1100px !important;
  height: inherit;
  overflow: hidden !important;

  .slick-list {
    width: 309px !important;
    height: 280px;
    max-height: 300px;
  }

  .slick-space {
    text-align: center;
    min-width: 209px;
    max-width: 280px !important;
    height: inherit;
  }

  .slick-slide {
    width: 560px !important;
    min-height: 272px;
    max-height: 280px !important;
    padding-right: 30px;
    margin-left: -8px;

    transition: all ease 0.3s;
  }

  .slick-slide.slick-current {
    /* padding-right: 0px;
    padding-left: 0px; */
  }

  .slick-list,
  .slick-slider,
  .slick-track {
    position: absolute;
    width: 100% !important;
    display: flex;
  }

  ${media.lessThan("medium")`
    
    .slick-slide {
      width: 320px !important;
      min-width: 380px !important;
      min-height: 405px;
      max-height: 410px !important;
      padding: 0;
      margin-left: 30px !important;
      
      transition: all ease 0.3s;
    }
    
    .slick-space {
   
      & img {
        width: 100% !important;
        min-width: 310px !important;
        max-width: 330px !important;
        height: 290px;
        margin: 0;
      }
   }
  `}

  ${customMedia.lessThan("iphone")`
    .slick-slide {
      width: 320px !important;
      min-width: 360px !important;
    }
  `}
`

export const NextArrowTestimony = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  z-index: 90;
  top: 0;
  right: 0;
  width: 80px;
  height: 280px;
  border: none;
  pointer-events: all;

  ${media.lessThan("medium")`
    top: 50px;
    height: 410px;
  `}
  & img {
    margin-right: -10px;

    &:hover {
      cursor: pointer;
    }
  }

  ${media.lessThan("large")`
     height: 280px;
  `}

`

export const PrevArrowTestimony = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  z-index: 90;
  top: 0;
  left: 0;
  width: 80px;
  height: 280px;
  border: none;
  pointer-events: all;

  ${media.lessThan("medium")`
    top: 50px;
    left: 10px;
    height: 410px;
  `}
  & img {
    margin-left: -10px;

    &:hover {
      cursor: pointer;
    }
  }

  ${media.lessThan("large")`
     height: 280px;
  `}
`

export const BoxTestimony = styled.div`
  ${({theme}) => css`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 530px;
    height: 272px;
    background: ${theme.colors.mainBg};
    color: ${theme.colors.white};
    border-radius: 0 70px 0 70px;
    padding: 15px;

    ${media.lessThan("medium")`
       width: 305px;
       height: 400px;
       flex-direction: column;
       align-items: center;
    `}

  `}

`

export const UserPhoto = styled.img`
  width: 180px;
  height: 180px;
  border-radius: 50%;
  margin-left: 10px;
  object-fit: cover;

  ${media.lessThan("medium")`
    object-fit: cover;
    width: 120px;
    height: 150px;
    border-radius: 50%;
    margin-left: 0;
  `}
`

export const BoxTexTestimony = styled.div`
  ${({theme}) => css`
    margin-top: 0.4rem;
    margin-left: ${theme.spacings.xsmall};

    ${media.lessThan("medium")`
      text-align: center;
      margin-top: 0;
      margin-left: 0;
    `}
    
    & h1 {
      font-family: ${theme.font.family};
      font-weight: ${theme.font.bold};
      font-size: ${theme.font.sizes.large};
      line-height: ${theme.font.sizes.xlarge};
      letter-spacing: 0;
      color: ${theme.colors.white};
    }

    & h2 {
      font-family: ${theme.font.family};
      font-weight: ${theme.font.normal};
      font-size: ${theme.font.sizes.medium};
      line-height: ${theme.font.sizes.xlarge};
      letter-spacing: 0;
      color: ${theme.colors.primary};
      text-transform: uppercase;

      ${media.lessThan("medium")`
        font-size: ${theme.font.sizes.small};
        line-height: ${theme.font.sizes.medium};
      `}
    }

    & h3 {
      font-family: ${theme.font.family};
      font-weight: ${theme.font.normal};
      font-size: ${theme.font.sizes.small};
      line-height: ${theme.font.sizes.medium};
      letter-spacing: 0;
      color: ${theme.colors.white};
    }
  `}
`

