import * as React from "react"
import {useEffect, useState} from "react"
import {Link} from "gatsby"

import * as S from "./styled"
import Logo from "../Logo";

const MenuMobile = () => {
  const [close, setClose] = useState(false)
  const [bodyOffset, setBodyOffset] = useState({top: 0})
  const [scrollY, setScrollY] = useState(bodyOffset.top)

  const toggleMenu = (): void => {
    setClose(!close)
  }

  const listener = () => {
    setBodyOffset(document.body.getBoundingClientRect())
    setScrollY(-bodyOffset.top)
  }

  useEffect(() => {
    window.addEventListener("scroll", listener)
    return () => {
      window.removeEventListener("scroll", listener)
    }
  })

  return (
    <div>
      <S.MobileMenu
        onClick={() => toggleMenu()}
        active={close}
        position={scrollY}
      />
      <S.CloseMenu
        onClick={() => toggleMenu()}
        isOpen={close}
        position={scrollY}
      />
      <S.MobileWrapper active={close}/>
      <S.MenuIn isOpen={close}>
        <S.MenuList to="#quem-somos" onClick={() => toggleMenu()}>
          quem somos
        </S.MenuList>
        <S.MenuList to="#modalidades" onClick={() => toggleMenu()}>
          modalidades
        </S.MenuList>
        <S.MenuList to="#treinos" onClick={() => toggleMenu()}>
          treinos
        </S.MenuList>
        <S.MenuList to="#coach" onClick={() => toggleMenu()}>
          coach
        </S.MenuList>
        <S.MenuList to="#planos" onClick={() => toggleMenu()}>
          planos
        </S.MenuList>
        <S.MenuList to="#contato" onClick={() => toggleMenu()}>
          contato
        </S.MenuList>
      </S.MenuIn>
    </div>
  )
}

export default MenuMobile
