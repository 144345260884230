import React from 'react'
import { StaticImage } from "gatsby-plugin-image"


import Button from '../Button'

import * as S from './styled'
import {Link} from "gatsby";

const HeroComponent = () => {
  return (
    <S.Wrapper>
      <S.Content>
        <S.ContentText>
          <S.SubTitle>TREINE COM OS MELHORES</S.SubTitle>
          <S.Title>ASSESSORIA</S.Title>
          <S.Title>CORRIDA E TRIATHLON</S.Title>
          <S.Description>Com 20 anos de experiência, desenvolvemos programas de treinamento nas diversas modalidades
            auxiliando no alcance dos objetivos dos nossos alunos.</S.Description>
          <Link to="#contato">
            <Button color="true">Entre para o time!</Button>
          </Link>
        </S.ContentText>
        <StaticImage src="../../images/arrow.png" alt="seta para baixo" />
      </S.Content>
    </S.Wrapper>
  );
};

export default HeroComponent;
