import React from 'react'

import * as S from './styled'

type TimeLine = {
  title: string
  text: string
}

export type TimeLineProps = {
  data: TimeLine
}

const TimeLineItem = ({data}: TimeLineProps) => {
  return (
    <S.TimeLineItem>
      <S.TimeLineContent>
        <S.TimeLineTitle>{data.title}</S.TimeLineTitle>
        <S.TimeLineText dangerouslySetInnerHTML={{ __html: data.text }} />
      </S.TimeLineContent>
    </S.TimeLineItem>
  );
};

export default TimeLineItem;
