import React from 'react';
import {StaticImage} from "gatsby-plugin-image";

import Pinned from '../Pinned'
import * as S from './styled'

const TrainingComponent = () => {
  return (
    <S.Wrapper id="treinos">
      <S.WrapperArcos>
        <S.Content>
          <S.Title>Treinos e Planilhas</S.Title>
          <S.ContentLocal>
            <S.City>
              <Pinned color="#FF8200"/>
              CAMPINAS/SP
            </S.City>
            <S.CityDescription>
              Para quem não reside em Campinas ou região e não poderá participar dos treinos coletivos, poderá fazer um
              treinamento a distância que demanda mais disciplina do atleta para atingir evolução desejada. <br />Porém
              ofereceremos a base para que a pessoa consiga atingir seus objetivos, sejam eles bater recordes pessoais,
              correr o primeiro quilômetro completo ou participar de mundiais. Atendemos vários estados do Brasil e
              também no exterior.
            </S.CityDescription>
          </S.ContentLocal>
          <S.ContentTraining>
            <S.Training>
              <h1>Treinos coletivos</h1>
              <p>Promovemos treinos coletivos com acompanhamento e suporte do treinador em Campinas e região.</p>

              <p><strong>TREINOS DE CICLISMO:</strong> no fim de semana são feitos em estradas seguras da região com
                apoio do treinador,
                hidratação , carro , moto e mecânico de bike pra qualquer intercorrência que venha acontecer durante o
                treino. </p>

              <p><strong>TREINOS DE CORRIDA:</strong> no fim de semana são feitos usando a estrutura da Padoca do Vila
                em Joaquim Egídio
                ou Taquaral com hidratação e frutas em todos os treinos.
              </p>
            </S.Training>
            <S.TimeDescription>
              <strong>TERÇA:</strong> 6h - Ciclismo
              <p> 17h - Natação (Cia Athletica) </p>
              <strong>QUARTA:</strong> 6h - Corrida <br/>
              <strong>QUINTA:</strong> 6h - Ciclismo<br/>
              <p>17h - Natação (Cia Athletica) </p>
              <strong>SEXTA:</strong> 6h - Corrida <br/>
              <strong>SÁBADO:</strong> 7h - Ciclismo (Longo) <br/>
              <strong>DOMINGO:</strong> 7h - Corrida (Longo)
            </S.TimeDescription>
          </S.ContentTraining>
          <S.ClubRM>
            <S.ClubRMName>
              <h1>CLUBE RM</h1>
              <StaticImage src="../../images/arrow.svg" alt="seta para baixo"/>
            </S.ClubRMName>
            <S.ClubRMDescription>
              Se você é de <strong>Campinas e Região</strong> e busca companhia pra treinar ciclismo e corrida com todo suporte e
              segurança. O <strong>Clube RM</strong> é um serviço que oferecemos , onde você pode participar e usufruir de todos os
              nossos treinos coletivos, estrutura em competições e eventos especiais que nós da <strong>RM Sports</strong> proporcionamos
              para todos nossos clientes. A única diferença é que não faremos nenhuma prescrição de treinamento .
            </S.ClubRMDescription>
          </S.ClubRM>
        </S.Content>
      </S.WrapperArcos>
    </S.Wrapper>
  );
};

export default TrainingComponent;
