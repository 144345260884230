import React from 'react';

export type PinnedProps = {
  color?: string
}

const Pinned = ({color = '#00b2a9'}: PinnedProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="35" viewBox="0 0 28 35">
      <g id="Layer_2" data-name="Layer 2" transform="translate(0 -0.423)">
        <g id="pin">
          <rect id="Rectangle_48" data-name="Rectangle 48" width="28" height="35" transform="translate(0 0.423)"
                fill={color} opacity="0"/>
          <g id="Group_48" data-name="Group 48" transform="translate(-0.48 0.553)">
            <path id="Path_100" data-name="Path 100"
                  d="M11.507,2A7.507,7.507,0,0,0,4,9.432c0,5.142,6.615,10.865,6.9,11.109a.938.938,0,0,0,1.22,0c.328-.244,6.9-5.968,6.9-11.11A7.507,7.507,0,0,0,11.507,2Zm0,16.561c-1.567-1.492-5.63-5.63-5.63-9.13a5.63,5.63,0,1,1,11.26,0C17.137,12.9,13.074,17.07,11.507,18.561Z"
                  transform="translate(0.973 0.487)" fill={color}/>
            <path id="Path_101" data-name="Path 101"
                  d="M11.784,6a3.284,3.284,0,1,0,3.284,3.284A3.284,3.284,0,0,0,11.784,6Zm0,4.692a1.407,1.407,0,1,1,1.407-1.407A1.407,1.407,0,0,1,11.784,10.692Z"
                  transform="translate(0.696 0.24)" fill={color}/>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Pinned;
