import React, {useRef} from "react";
import Slider from "react-slick";

import ArrowLeft from '../../images/arrow-left.svg'
import ArrowRight from '../../images/arrow-right.svg'

import * as S from './styled'
import TestimonyItem from "./TestimonyItem";
import {UserTestimonyProps} from "./";

type TestimonyProps = {
  testimony: UserTestimonyProps[]
}

const NextArrow = () => {
  return (
    <S.NextArrow><img src={ArrowRight} alt=""/></S.NextArrow>
  );
}

const PrevArrow = () => {
  return (
    <S.PrevArrow><img src={ArrowLeft} alt=""/></S.PrevArrow>
  );
}

const CarouselSlider = ({testimony}: TestimonyProps) => {

  const customeSlider = useRef();

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          cssEase: "linear",
        }
      }
    ]
  };

  const gotoNext = () => {
    // @ts-ignore
    customeSlider.current.slickNext()
  }

  const gotoPrev = () => {
    // @ts-ignore
    customeSlider.current.slickPrev()
  }

  return (
    <S.CarouselTestimonyWrapper>
      <S.PrevArrowTestimony onClick={() => gotoPrev()}><img src={ArrowLeft} alt=""/></S.PrevArrowTestimony>
      <S.NextArrowTestimony onClick={() => gotoNext()}><img src={ArrowRight} alt=""/></S.NextArrowTestimony>
      <S.CarouselTestimony>
        <Slider ref={customeSlider} {...settings}>
          {testimony.map((item, index) => (
              <TestimonyItem key={index} {...item}/>
            )
          )}

        </Slider>
      </S.CarouselTestimony>
    </S.CarouselTestimonyWrapper>
  );
}

export default CarouselSlider
