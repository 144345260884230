import React, {useState} from 'react'

import * as S from './styled'
import {images} from "./image";
import Carousel from "./Carousel";
import Sections from "./Sections";

const ModalityComponent = () => {
  const [hidden, setHidden] = useState(false);
  const [activeImage, setActiveImage] = useState('');
  const [modality, setModality] = useState('triathlon');

  function getCoords(elem) {
    let box = elem.getBoundingClientRect();

    return {
      top: box.top + window.pageYOffset,
      right: box.right + window.pageXOffset,
      bottom: box.bottom + window.pageYOffset,
      left: box.left + window.pageXOffset
    };
  }

  const handleModality = (slug) => {
    if (window.innerWidth <= 768) {
      let page = document.createElement('body');
      const element = document.getElementById(`${slug}`);
      let coords = getCoords(element);

      scrollTo(0, coords.bottom - 600)

      // @ts-ignore
      // element.scrollIntoView({behavior: 'smooth'}, true);
    }

    setModality(slug);

  }

  const list = [
    {
      title: 'TRIATHLON, AQUATLHON E DUATHLON',
      slug: 'triathlon'
    },
    {
      title: 'CORRIDA DE RUA',
      slug: 'corrida'
    },
    {
      title: 'CICLISMO',
      slug: 'ciclismo'
    },
    {
      title: 'NATAÇÃO',
      slug: 'natacao'
    },
    {
      title: 'COACHING',
      slug: 'coaching'
    },
    {
      title: 'PALESTRAS',
      slug: 'palestras'
    },
  ]

  const getModality = (type) => {
    let modality = {
      'triathlon': '<p>Você tem duas treinadoras especialistas em triathlon, com vasta experiência em provas de todas as distâncias que vão te levar para um nível altamente diferenciado. Sua planilha estará de acordo com sua rotina de vida propiciando um melhor aproveitamento de seu tempo disponível para treinar. <br /><br />Se você nunca treinou triathlon, não tem bicicleta e é totalmente inexperiente pode iniciar com treinos indoor e gradativamente passará a treinos outdoor. <br /><br />Se você já tem treinamento, prepare-se para ter resultados nunca imaginados. <br /><br />Venha fazer parte deste time! </p>',
      'corrida': '<p>Trabalhamos com performance individual, fazendo um planejamento especial para que você evolua a cada dia. Se você nunca correu temos vários projetos específicos que te levarão a ser um corredor de verdade. Treinos modernos, diferenciados e inteligentes que te manterá focado e motivado pois os resultados aparecem rapidamente. <br /><br />Venha decolar com a gente!</p>',
      'ciclismo': '<p>Trabalhamos com vários ciclistas que buscam evoluir o ciclismo e participar de provas de todos os níveis. Se você tem medidor de potência terá um planejamento bem específico e com estratégias personalizadas para sua prova. <br /><br /> Acelere com a RM!</p>',
      'natacao': '<p>A treinadora Rosana Merino foi atleta de alto rendimento na natação e elabora treinos de alta performance para atletas que desejam torna-se competitivos e treinos gradativos para você que está iniciando seus treinamentos na modalidade. <br /><br /> Venha treinar com quem realmente entende do assunto!</p>',
      'coaching': 'A Rosana Merino é pós-graduada em Coaching pelo IBC, trabalhou durante dois anos com Coaching Esportivo e atualmente usa como ferramenta para elevar seus atletas a um nível elevado. <br /><br /> O trabalho mental é um diferencial que a treinadora ensina a seus atletas devido a vasta experiência que tem nesta área.</p>',
      'palestras': '<p>Rosana Merino além de treinadora, faz palestra motivacionais para muitas empresas levando o time a mudar o Mindset e apresentar o seu melhor resultado. <br /><br /> Algumas em Empresas que trabalhamos: Unimed, GM Internacional, Itaú, Unifaj, Unimax, Nortel, Mônaco Investimentos e outras.</p>',
    };
    return modality[type];
  }

  const openModal = (image: string) => {
    setActiveImage(image)
    setHidden(true)
  }

  return (
    <S.Wrapper id="modalidades">
      <S.InternalWrapper>
        <S.Title>Modalidades</S.Title>
        <S.Content>
          <S.ContainerLinks>
            <ul>
              {list.map((item, index) => {
                return (
                  <div key={index}>
                    <S.Links
                      onClick={() => handleModality(item.slug)}
                      active={item.slug === modality ?? true}
                      id={item.slug}
                    >
                      {item.title}
                    </S.Links>
                    <Sections active={item.slug === modality ?? true} text={getModality(modality)}/>
                  </div>
                )
              })}
            </ul>
          </S.ContainerLinks>
          <S.Description>
            <span dangerouslySetInnerHTML={{__html: getModality(modality)}}/>
          </S.Description>
        </S.Content>
        <S.ContentImages>
          <S.BoxImage modal={hidden} image={activeImage} onClick={() => setHidden(false)}/>
          <Carousel images={images} openModal={(e) => openModal(e)}/>
        </S.ContentImages>
      </S.InternalWrapper>
    </S.Wrapper>
  );
};

export default ModalityComponent;
