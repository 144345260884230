import styled, {css} from "styled-components"
import media, {generateMedia} from 'styled-media-query'

import Speedo from '../../images/logo-speedo-hover.png'
import SpeedoHover from '../../images/logo-speedo.png'
import Atillatte from '../../images/logo-atilatte-hover.png'
import AtillatteHover from '../../images/logo-atilatte.png'
import Padoca from '../../images/logo-padoc-hover.png'
import PadocaHover from '../../images/logo-padoc.png'
import Euro from '../../images/logo-eurocycle-hover.png'
import EuroHover from '../../images/logo-eurocycle.png'

const customMedia = generateMedia({
  wide: '1441px',
  ultra: '2000px'
});

export const Wrapper = styled.div`
  ${({theme}) => css`
    width: 100%;
    height: 230px;
    background-color: ${theme.colors.darkGray};

    ${media.lessThan('medium')`
      height: 540px;
    `}
    
  `}
`

export const Content = styled.div.attrs(() => ({
  className: 'container',
}))`
  max-width: 1100px !important;
  height: 100%;
  max-height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 0 !important;

  ${media.lessThan('large')`
    max-width: 880px !important;
  `}
`

export const Title = styled.div`
  ${({theme}) => css`
    width: 100%;
    font-family: ${theme.font.family};
    font-weight: ${theme.font.ultra};
    font-size: ${theme.font.sizes.xlarge};
    line-height: ${theme.font.sizes.xxlarge};
    letter-spacing: 0;
    color: ${theme.colors.gray};
    text-transform: uppercase;
    text-align: left;
    padding: 40px 0 15px;

    ${media.lessThan('large')`
      padding: 50px 0 15px;
    `}

    ${media.lessThan('medium')`
       text-align: center;
    `}
  `}
`

export const ContentPartners = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 80px;

  ${media.lessThan('large')`
    flex-direction: column;
    align-items: center;
    height: inherit;
    margin-bottom: 40px;
  `}
`

export const SpeedoPartner = styled.div`
  width: 198px;
  height: 40px;
  margin-top: 20px;
  background: url(${Speedo}) no-repeat;
  
  &:hover {
    cursor: pointer;
    background-image: url(${SpeedoHover});
  }
`

export const AtilattePartner = styled.div`
  width: 80px;
  height: 80px;
  background: url(${Atillatte}) no-repeat;
  
  &:hover {
    cursor: pointer;
    background-image: url(${AtillatteHover});
  }
`

export const PadocaPartner = styled.div`
  width: 74px;
  height: 79px;
  background: url(${Padoca}) no-repeat;
  
  &:hover {
    cursor: pointer;
    background-image: url(${PadocaHover});
  }
`

export const EuroPartner = styled.div`
  width: 130px;
  height: 79px;
  background: url(${Euro}) no-repeat;
  
  &:hover {
    cursor: pointer;
    background-image: url(${EuroHover});
  }
`
