import styled, {css} from "styled-components"

export type ButtonProps = {
  size?: string
  color?: boolean | string
}

export const Wrapper = styled.div<ButtonProps>`
  ${({theme, size, color}) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${size ? '240px' : '219px'};
    height: 37px;
    background-color: ${color ? theme.colors.primary : theme.colors.info};
    border-radius: 30px;
    color: ${theme.colors.white};
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.bold};

    &:hover {
      cursor: pointer;
      box-shadow: 0 4px 8px ${color ? theme.colors.hover.primary : theme.colors.hover.secondary};
    }
  `}
  
  
`
