import React, {useState} from 'react';

const IconFacebook = () => {
  const [color, setColor] = useState('#fff');
  return (
    <svg id="Component_7_1" data-name="Component 7 – 1" xmlns="http://www.w3.org/2000/svg" width="30.047"
         height="30.047" viewBox="0 0 30.047 30.047" onMouseOver={() => setColor('#00B2A9')}
         onMouseOut={() => setColor('#fff')}>
      <path id="Path_104" data-name="Path 104"
            d="M30.47,4H7.577A3.578,3.578,0,0,0,4,7.577V30.47a3.578,3.578,0,0,0,3.577,3.577H30.47a3.578,3.578,0,0,0,3.577-3.577V7.577A3.578,3.578,0,0,0,30.47,4ZM27.609,14.731H26.178c-1.531,0-2.146.358-2.146,1.431v2.146h3.577l-.715,3.577H24.032V32.617H20.455V21.885H17.593V18.308h2.862V16.162c0-2.862,1.431-5.008,4.292-5.008a4.414,4.414,0,0,1,2.862.715Z"
            transform="translate(-4 -4)" fill={color}/>
    </svg>

  );
};

export default IconFacebook;
