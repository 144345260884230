import Image1 from '../../images/modalidades/image-1.jpg'
import Image2 from '../../images/modalidades/image-2.jpg'
import Image3 from '../../images/modalidades/image-3.jpg'
import Image4 from '../../images/modalidades/image-4.jpg'
import Image5 from '../../images/modalidades/image-5.jpg'
import Image6 from '../../images/modalidades/image-6.jpg'
import Image7 from '../../images/modalidades/image-7.jpg'
import Image8 from '../../images/modalidades/image-8.jpg'
import Image9 from '../../images/modalidades/image-9.jpg'
import Image10 from '../../images/modalidades/image-10.jpg'
import Image11 from '../../images/modalidades/image-11.jpg'
import Image12 from '../../images/modalidades/image-12.jpg'
import Image13 from '../../images/modalidades/image-13.jpg'
import Image14 from '../../images/modalidades/image-14.jpg'
import Image15 from '../../images/modalidades/image-15.jpg'
import Image16 from '../../images/modalidades/image-16.jpg'
import Image17 from '../../images/modalidades/image-17.jpg'
import Image18 from '../../images/modalidades/image-18.jpg'
import Image19 from '../../images/modalidades/image-19.jpg'
import Image20 from '../../images/modalidades/image-20.jpg'
import Image21 from '../../images/modalidades/image-21.jpg'
import Image22 from '../../images/modalidades/image-22.jpg'
import Image23 from '../../images/modalidades/image-23.jpg'
import Image24 from '../../images/modalidades/image-24.jpg'
import Image25 from '../../images/modalidades/image-25.jpg'
import Image26 from '../../images/modalidades/image-26.jpg'
import Image27 from '../../images/modalidades/image-27.jpg'
import Image28 from '../../images/modalidades/image-28.jpg'
import Image29 from '../../images/modalidades/image-29.jpg'
import Image30 from '../../images/modalidades/image-30.jpg'
import Image31 from '../../images/modalidades/image-31.jpg'
import Image32 from '../../images/modalidades/image-32.jpg'
import Image33 from '../../images/modalidades/image-33.jpg'
import Image34 from '../../images/modalidades/image-34.jpg'
import Image35 from '../../images/modalidades/image-35.jpg'
import Image36 from '../../images/modalidades/image-36.jpg'
import Image37 from '../../images/modalidades/image-37.jpg'
import Image38 from '../../images/modalidades/image-38.jpg'
import Image39 from '../../images/modalidades/image-39.jpg'
import Image40 from '../../images/modalidades/image-40.jpg'
import Image41 from '../../images/modalidades/image-41.jpg'
import Image42 from '../../images/modalidades/image-42.jpg'
import Image43 from '../../images/modalidades/image-43.jpg'
import Image44 from '../../images/modalidades/image-44.jpg'
import Image45 from '../../images/modalidades/image-45.jpg'
import Image46 from '../../images/modalidades/image-46.jpg'
import Image47 from '../../images/modalidades/image-47.jpg'
import Image48 from '../../images/modalidades/image-48.jpg'
import Image49 from '../../images/modalidades/image-49.jpg'
import Image50 from '../../images/modalidades/image-50.jpg'
import Image51 from '../../images/modalidades/image-51.jpg'
import Image52 from '../../images/modalidades/image-52.jpg'
import Image53 from '../../images/modalidades/image-53.jpg'
import Image54 from '../../images/modalidades/image-54.jpg'
import Image55 from '../../images/modalidades/image-55.jpg'
import Image56 from '../../images/modalidades/image-56.jpg'
import Image57 from '../../images/modalidades/image-57.jpg'
import Image58 from '../../images/modalidades/image-58.jpg'
import Image59 from '../../images/modalidades/image-59.jpg'
import Image60 from '../../images/modalidades/image-60.jpg'
import Image61 from '../../images/modalidades/image-61.jpg'
import Image62 from '../../images/modalidades/image-62.jpg'
import Image63 from '../../images/modalidades/image-63.jpg'
import Image64 from '../../images/modalidades/image-64.jpg'
import Image65 from '../../images/modalidades/image-65.jpg'
import Image66 from '../../images/modalidades/image-66.jpg'
import Image67 from '../../images/modalidades/image-67.jpg'
import Image68 from '../../images/modalidades/image-68.jpg'
import Image69 from '../../images/modalidades/image-69.jpg'
import Image70 from '../../images/modalidades/image-70.jpg'
import Image71 from '../../images/modalidades/image-71.jpg'

export const images = [
  {
    url: Image1
  },
  {
    url: Image2
  },
  {
    url: Image3
  },
  {
    url: Image4
  },
  {
    url: Image5
  },
  {
    url: Image6
  },
  {
    url: Image7
  },
  {
    url: Image8
  },
  {
    url: Image9
  },
  {
    url: Image10
  },
  {
    url: Image11
  },
  {
    url: Image12
  },
  {
    url: Image13
  },
  {
    url: Image14
  },
  {
    url: Image15
  },
  {
    url: Image16
  },
  {
    url: Image17
  },
  {
    url: Image18
  },
  {
    url: Image19
  },
  {
    url: Image20
  },
  {
    url: Image21
  },
  {
    url: Image22
  },
  {
    url: Image23
  },
  {
    url: Image24
  },
  {
    url: Image25
  },
  {
    url: Image26
  },
  {
    url: Image27
  },
  {
    url: Image28
  },
  {
    url: Image29
  },
  {
    url: Image30
  },
  {
    url: Image31
  },
  {
    url: Image32
  },
  {
    url: Image33
  },
  {
    url: Image34
  },
  {
    url: Image35
  },
  {
    url: Image36
  },
  {
    url: Image37
  },
  {
    url: Image38
  },
  {
    url: Image39
  },
  {
    url: Image40
  },
  {
    url: Image41
  },
  {
    url: Image42
  },
  {
    url: Image43
  },
  {
    url: Image44
  },
  {
    url: Image45
  },
  {
    url: Image46
  },
  {
    url: Image47
  },
  {
    url: Image48
  },
  {
    url: Image49
  },
  {
    url: Image50
  },
  {
    url: Image51
  },
  {
    url: Image52
  },
  {
    url: Image53
  },
  {
    url: Image54
  },
  {
    url: Image55
  },
  {
    url: Image56
  },
  {
    url: Image57
  },
  {
    url: Image58
  },
  {
    url: Image59
  },
  {
    url: Image60
  },
  {
    url: Image61
  },
  {
    url: Image62
  },
  {
    url: Image63
  },
  {
    url: Image64
  },
  {
    url: Image65
  },
  {
    url: Image66
  },
  {
    url: Image67
  },
  {
    url: Image68
  },
  {
    url: Image69
  },
  {
    url: Image70
  },
  {
    url: Image71
  },
]
