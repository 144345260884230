import styled, {css} from "styled-components"
import media from 'styled-media-query'

import ArcoSuperior from '../../images/arco-superior.svg'
import ArcoInferior from '../../images/arco-inferior.svg'
import TrainingBG from '../../images/training-bg.jpg'


export const Wrapper = styled.div`
  ${({theme}) => css`
    width: 100%;
    height: 100%;
    min-height: 1150px;
    background-image: url(${TrainingBG});
    background-repeat: no-repeat;
    background-position: top -200px center;
    background-size: cover;

    ${media.lessThan("large")`
      background-size: 150%; 
    `}

    ${media.lessThan("medium")`
      min-height: 1500px;
      background-position: top -200px right -300px;
      background-size: auto 2100px;
    `}

  `}
`

export const WrapperArcos = styled.div`
  width: 100%;
  height: 100%;
  min-height: inherit;
  background-image: url(${ArcoSuperior}), url(${ArcoInferior});
  background-repeat: no-repeat;
  background-position: bottom -1px center, top -1px center;
  background-size: 110%;

  ${media.lessThan("medium")`
      background-size: 350%; 
    `}
`

export const Content = styled.div.attrs(() => ({
  className: 'container',
}))`
  max-width: 880px !important;
  height: 100%;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 170px;

  ${media.lessThan("medium")`
      max-width: 96% !important;
      padding-top: 100px;
      // display: none;
  `}
`

export const Title = styled.div`
  ${({theme}) => css`
    width: 100%;
    font-family: ${theme.font.family};
    font-weight: ${theme.font.ultra};
    font-size: ${theme.font.sizes.huge};
    line-height: ${theme.font.sizes.xlarge};
    letter-spacing: 0;
    color: ${theme.colors.white};
    text-transform: uppercase;
    text-align: left;
    padding: 60px 0 70px;

    ${media.lessThan("medium")`
      font-size: 3rem;
      text-align: center;
      padding: 60px 0 30px;
    `}
  `}
`

export const ContentLocal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  height: 100%;
  margin-bottom: 60px;

  ${media.lessThan("medium")`
      width: 100%;
      margin: 0 auto;
      flex-direction: column;
  `}
`

export const City = styled.div`
  ${({theme}) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.xlarge};
    font-weight: ${theme.font.ultra};
    line-height: ${theme.font.sizes.xlarge};
    letter-spacing: 0;
    color: ${theme.colors.white};
    text-transform: uppercase;

    svg {
      padding-top: 10px;
      width: 45px;
      height: 55px;
    }

    ${media.lessThan("medium")`
        width: 100%;
        text-align: left;
        margin-bottom: 15px;
        
        svg {
          padding-top: 10px;
          width: 55px;
          height: 65px;
        }
    `}
  `}

`

export const CityDescription = styled.div`
  ${({theme}) => css`
    width: 70%;
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.normal};
    line-height: ${theme.font.sizes.xlarge};
    letter-spacing: 0;
    color: ${theme.colors.white};

    ${media.lessThan("medium")`
        width: 100%;
        text-align: left;
        margin-bottom: 40px;
    `}
  `}
`

export const ContentTraining = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  min-height: 100px;
  margin: 0 auto;

  ${media.lessThan("medium")`
      flex-direction: column;
  `}
`
export const Training = styled.div`
  ${({theme}) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 90%;
    height: inherit;
    margin-right: 6%;
    min-height: inherit;
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.large};
    font-weight: ${theme.font.ultra};
    line-height: ${theme.font.sizes.xlarge};
    letter-spacing: 0;
    color: ${theme.colors.white};

    & h1 {
      font-size: ${theme.font.sizes.xxlarge};
      font-weight: ${theme.font.ultra};
      line-height: ${theme.font.sizes.xlarge};
      margin: 10px 0 25px;
      text-transform: uppercase;
    }

    & p {
      font-size: ${theme.font.sizes.medium};
      font-weight: ${theme.font.normal};
      line-height: ${theme.font.sizes.large};
      margin-bottom: ${theme.font.sizes.xlarge};
    }

    & span {
      font-weight: ${theme.font.bold};
      color: ${theme.colors.info};
      line-height: ${theme.font.sizes.xlarge};
    }

    ${media.lessThan("medium")`
       width: 100%;
       & h1 {
          font-size: 3rem;
       }
    `}

  `}

`

export const TimeDescription = styled.div`
  ${({theme}) => css`
    width: 49%;
    height: inherit;
    min-height: inherit;
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.normal};
    line-height: ${theme.font.sizes.xxlarge};
    color: ${theme.colors.white};
    padding: ${theme.font.sizes.small};
    border-radius: 32px;
    border: 1px solid ${theme.colors.info};

    & p {
      padding-left: ${theme.font.sizes.huge};
      margin: 0;
    }

    ${media.lessThan("medium")`
       width: 100%;
    `}

  `}
`

export const ClubRM = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 98%;
  height: 100%;
  margin: 50px 0;

  ${media.lessThan("medium")`
     width: 100%;
     flex-direction: column;
     margin: 25px 0;
  `}
`

export const ClubRMName = styled.div`
  ${({theme}) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 25%;
    height: 100%;

    & h1 {
      font-family: ${theme.font.family};
      font-size: ${theme.font.sizes.xxlarge};
      font-weight: ${theme.font.top};
      line-height: ${theme.font.sizes.xxlarge};
      color: ${theme.colors.primary};
      text-align: left;
      letter-spacing: 0;
      text-transform: uppercase;
      padding-top: ${theme.spacings.xxsmall};
      padding-bottom: ${theme.spacings.xxsmall};
      opacity: 1;
    }

    ${media.lessThan("medium")`
       width: 100%;
        flex-direction: row;
       align-items: flex-start;
       
       & h1 {
        padding-bottom: ${theme.spacings.xxsmall};
       }
       
       img { 
          display: none;
       }
    `}

  `}
`

export const ClubRMDescription = styled.div`
  ${({theme}) => css`
    width: 80%;
    height: 100%;
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.normal};
    line-height: ${theme.font.sizes.xlarge};
    letter-spacing: 0;
    color: ${theme.colors.white};
    padding-left: 3%;

    ${media.lessThan("medium")`
      width: 100%;
      padding-left: 0;
    `}
  `}

`
