import styled, {css} from "styled-components"
import media, {generateMedia} from 'styled-media-query'

import ContactBG from '../../images/contact-bg.png'
import ContactBGUP from "../../images/contact-bg-up.png";

const customMedia = generateMedia({
  wide: '1441px',
  ultra: '2000px'
});

export const Wrapper = styled.div`
  ${({theme}) => css`
    width: 100%;
    height: 100%;
    min-height: 1000px;
    background-image: url(${ContactBG});
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100%;

    ${media.lessThan('medium')`
      background-position: top right -290px;
      background-size: 260%;
    `}


    ${media.lessThan('large')`
      min-height: 780px;
    `}

    ${media.between('large', 'huge')`
      min-height: 950px;
    `}

    ${customMedia.greaterThan("wide")`
      min-height: 1100px;
    `}

    ${customMedia.greaterThan("ultra")`
      min-height: 1080px;
      background-size: 100% 800px;
    `}

  `}
`

export const Container = styled.div`
  height: 690px;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  background-image: url(${ContactBGUP});
  background-repeat: no-repeat;
  background-size: 100%;

  ${media.lessThan('medium')`
      min-height: 1050px;
      background-position: top right -290px;
      background-size: 260%;
  `}

  ${customMedia.greaterThan("ultra")`
    background-size: 100% 800px;
  `}

`

export const Content = styled.div.attrs(() => ({
  className: 'container',
}))`
  max-width: 1100px !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 80px;
  margin-bottom: 300px;

  ${media.lessThan('medium')`
   max-width: 95% !important;
   padding: 20px !important;
   padding-top: 80px !important;
   margin-bottom: 130px !important;
  `}

  ${media.lessThan('large')`
    max-width: 880px !important;
    padding-top: 40px;
    margin-bottom: 160px;
  `}

  ${media.between('large', 'huge')`
    padding-top: 60px;
    margin-bottom: 250px;
  `}

  ${customMedia.greaterThan("wide")`
    padding-top: 100px;
    margin-bottom: 320px;
  `}

  ${customMedia.greaterThan("ultra")`
    background-size: 100% 800px;
    margin-bottom: 280px;
    margin-bottom: 290px;
  `}

`

export const Title = styled.div`
  ${({theme}) => css`
    width: 100%;
    font-family: ${theme.font.family};
    font-weight: ${theme.font.ultra};
    font-size: ${theme.font.sizes.huge};
    line-height: ${theme.font.sizes.xlarge};
    letter-spacing: 0;
    color: ${theme.colors.white};
    text-transform: uppercase;
    text-align: center;
    padding: 30px 0 150px;

    ${media.lessThan('medium')`
       font-size: ${theme.font.sizes.xxlarge};
       text-align: left;
       padding: 30px 0 30px !important;
    `}

    ${media.lessThan('large')`
      padding-bottom: 100px;
    `}

    ${customMedia.greaterThan("wide")`
      padding-bottom: 200px;
    `}
  `}
`

export const Description = styled.div`
  ${({theme}) => css`
    width: 50%;
    min-height: 200px;
    font-family: ${theme.font.family};
    font-weight: ${theme.font.normal};
    font-size: ${theme.font.sizes.medium};
    line-height: ${theme.font.sizes.xlarge};
    letter-spacing: 0;
    color: ${theme.colors.white};

    ${media.lessThan('medium')`
       width: 100%;
    `}
    & a {
      text-decoration: none;
      color: ${theme.colors.white};
      font-size: ${theme.font.sizes.xlarge};
      font-weight: ${theme.font.bold};
      margin-bottom: 24px;

      &:hover {
        color: ${theme.colors.primary};
      }
    }

    & p {
      width: 75%;
      margin-bottom: 24px;
    }

    & span {
      font-size: ${theme.font.sizes.xlarge};
      font-weight: ${theme.font.bold};
    }

  `}

`

export const BoxList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 43px;

  & span {
    margin-left: 16px;
  }
`

export const WrapperFooter = styled.div.attrs(() => ({
  className: 'container',
}))`
  max-width: 1150px !important;
  height: 150px;
  max-height: 180px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${media.lessThan('medium')`
    width: 95% !important;
    max-width: 95% !important;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 100% !important;
    height: 120px;
    max-height: 150px;
    
    &::first-child {
      border: 1px solid yellow !important;
    }
  `}

  ${media.lessThan('large')`
    max-width: 880px !important;
  `}
`

export const Logo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
  height: inherit;
`

type MenuProps = {
  centered: boolean,
  grow: number
}

export const Menu = styled.div<MenuProps>`
  ${({theme, centered, grow = 1}) => css`
    display: flex;
    justify-content: flex-start;
    align-items: ${centered ? 'center' : 'flex-start'};
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.bold};
    flex-grow: ${grow};
    height: inherit;
    padding-top: 10px;

    ${media.lessThan('medium')`
      align-items: flex-start;
      flex-grow: 0;
      height: 100px;
    `}

    & svg {
      margin-top: 14px;
      margin-right: 8px;

      &:hover {
        cursor: pointer;
      }
    }

    &:last-child {
      font-weight: ${theme.font.ultra};
      flex-direction: column;
      align-items: center;

      & span {

        flex-direction: row;

        & img {
          margin: ${theme.spacings.xxsmall} 5px;
          width: 30px;
          height: 30px;
        }
      }
    }

    & ul {
      width: 100%;
      list-style: none;
      padding: 0;
      font-family: ${theme.font.family};
      font-style: normal;
      font-weight: 500;
      font-size: ${theme.font.sizes.medium};
      line-height: 124.5%;
      color: #FBFBFB30;

      li {
        display: flex;
        margin-bottom: 15px;
      }

      & a {
        text-decoration: none;
        color: ${theme.colors.white};

        &:hover {
          color: ${theme.colors.primary};
        }
      }
    }
  `}

`

export const ViewMenuDesktop = styled.div`
  width: 65% !important;
  display: flex;
  ${media.lessThan('medium')`
    display: none;
  `}
`

export const ViewMenuMobile = styled.div`
  display: none;
  
  ${media.lessThan('medium')`
    display: flex;
    justify-content: center;
    width: 95% !important;
    max-width: 95% !important;
    margin: 0 auto;
    
    ${Menu} {
      height: 100% !important;
      min-height: 250px;
    }
    
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px !important;
    }
  `}

`

export const CopyRight = styled.div`
  ${({theme}) => css`
    width: 100%;
    text-align: center;
    font-family: ${theme.font.family};
    font-style: normal;
    font-weight: 500;
    font-size: ${theme.font.sizes.medium};
    line-height: 124.5%;
    color: ${theme.colors.white};
    padding-top: 35px;

    ${media.lessThan('medium')`
      padding-top: 10px;
    `}
  `}

`
