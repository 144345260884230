import styled, {css} from "styled-components"
import media from 'styled-media-query'

import Arrow from '../../images/arrow-plans.svg'

export const Wrapper = styled.div`
  ${({theme}) => css`
    width: 100%;
    height: 100%;
    min-height: 760px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: ${theme.colors.mainBg};
    padding-top: 20px;
    padding-bottom: 80px;

    ${media.lessThan("medium")`
        padding-top: 0;
        padding-bottom: 40px;
    `}
  `}
`

export const Content = styled.div.attrs(() => ({
  className: 'container',
}))`
  max-width: 1100px !important;

  ${media.lessThan("medium")`
      width: 95% !important;
      margin: 0 auto;
  `}

`

export const Title = styled.div`
  ${({theme}) => css`
    width: 100%;
    font-family: ${theme.font.family};
    font-weight: ${theme.font.ultra};
    font-size: ${theme.font.sizes.huge};
    line-height: ${theme.font.sizes.xlarge};
    letter-spacing: 0;
    color: ${theme.colors.white};
    text-transform: uppercase;
    text-align: center;
    padding: 35px 0 70px;

    ${media.lessThan("medium")`
      font-size: ${theme.font.sizes.xxlarge};
      padding: 35px 0 50px;
    `}
  `}
`

export const BoxPlans = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;

  ${media.lessThan("medium")`
      flex-direction: column;
      margin-bottom: 70px;
  `}
`

export type BoxProps = {
  lado?: boolean
  color?: string | boolean
}

export const Box = styled.div<BoxProps>`
  ${({theme, lado}) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 420px;
    height: 100%;
    min-height: 635px;
    background-color: ${theme.colors.altBg};
    border: 1px solid ${theme.colors.gray};
    padding: 0 23px;
    margin-right: ${lado ? '30px' : '0'};
    margin-bottom: 30px;
    border-radius: ${lado ? '70px 0 70px 70px' : '0 70px 70px 70px'};
    
    a {
      text-decoration: none;
    }

    ${media.lessThan("medium")`
      min-height: 685px;
      margin-right: 0;
      margin-bottom: 60px;
      border-radius: 70px 0 70px 70px;
    `}

  `}

`

export const BoxTitle = styled.div<BoxProps>`
  ${({theme, lado}) => css`
    width: 100%;
    font-family: ${theme.font.family};
    font-weight: ${theme.font.ultra};
    font-size: ${theme.font.sizes.xxlarge};
    line-height: ${theme.font.sizes.xxlarge};
    letter-spacing: 0;
    color: ${lado ? theme.colors.info : theme.colors.primary};
    text-transform: uppercase;
    text-align: center;
    margin: 30px 0 35px;
  `}
`

export const BoxContent = styled.div<BoxProps>`
  ${({theme}) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 450px;
    font-family: ${theme.font.family};
    font-weight: ${theme.font.ultra};
    font-size: ${theme.font.sizes.medium};
    line-height: ${theme.font.sizes.xxlarge};
    letter-spacing: 0;
    color: ${theme.colors.white};
  `}
`

export const BoxButton = styled.div<BoxProps>`
  position: relative;
  bottom: -60px;

  ${media.lessThan("medium")`
      bottom: -115px;
  `}
`

export const BoxList = styled.div<BoxProps>`
  ${({color}) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    height: 43px;
    margin-bottom: 14px;

    & span {
      margin-left: 16px;
      color: ${color !== '#717171' ? '#FFF' : '#717171'}
    }

    ${media.lessThan("medium")`
      & span {
         padding-top: 3px;
         line-height: 2.1rem;
      }
         
    `}
  `}
`

export const BoxPremium = styled.div<BoxProps>`
  ${({theme}) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 865px;
    height: 100%;
    min-height: 185px;
    background-color: ${theme.colors.altBg};
    border: 1px solid ${theme.colors.gray};
    padding: 30px;
    margin-bottom: 30px;
    border-radius: 80px 0 80px 80px;

    & h1 {
      font-family: ${theme.font.family};
      font-weight: ${theme.font.ultra};
      font-size: ${theme.font.sizes.xxlarge};
      line-height: ${theme.font.sizes.xxlarge};
      margin-bottom: 10px;
    }

    & h2 {
      font-family: ${theme.font.family};
      font-weight: ${theme.font.normal};
      font-size: ${theme.font.sizes.medium};
      text-align: center;
    }

    ${media.lessThan("medium")`
      margin-bottom: 0;
      & h1 {
        font-size: calc(${theme.font.sizes.xxlarge} + 0.4rem);
        margin-bottom: 15px;
      }
      & h2 {
        font-size: ${theme.font.sizes.large};
      }
         
    `}
    
  `}

`

export const ArrowPremium = styled.div`
  position: absolute;
  right: -15px;
  top: -80px;
  width: 162px;
  height: 94px;
  background-image: url(${Arrow});
  background-repeat: no-repeat;
  transform: rotate(230deg);

  ${media.lessThan("medium")`
      right: -5px;
      top: -80px;
      transform: rotate(210deg);
  `}
  
`;
