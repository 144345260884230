import React from "react"

import SEO from "./seo";
import theme from "../../styles/theme";
import { ThemeProvider } from "styled-components";

import * as S from './styled'
import GlobalStyles from '../../styles/global'

type LayoutProps = {
  children: React.ReactNode
}

const Layout = ({children}: LayoutProps) => {

  return (
    <S.Wrapper>
      <SEO title="Home"/>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </S.Wrapper>
  )
}

export default Layout
