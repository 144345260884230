import React from "react";

type LogoProps = {
  color?: string
}

export const Logo = ({ color = "#fff" }: LogoProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="101"
      height="49"
      data-name="Layer 2"
      viewBox="0 0 94.21 45.229"
    >
      <g fill={color} data-name="Camada 1">
        <path
          d="M26.314.06A26.906 26.906 0 000 26.962v6.705h13.574v-6.705a13.411 13.411 0 0112.74-13.39z"
          data-name="Path 2"
          transform="translate(0 -.052)"
        />
        <path
          d="M252.388 0a26.841 26.841 0 00-20.2 9.134A26.84 26.84 0 00212.74.01v13.5a13.411 13.411 0 0112.74 13.39v6.705h13.415v-6.7a13.413 13.413 0 1126.826 0v6.705h13.574v-6.7A26.909 26.909 0 00252.388 0z"
          data-name="Path 3"
          transform="translate(-185.084)"
        />
        <path
          d="M2.547 292.747a2.147 2.147 0 00-.39-.033 1.4 1.4 0 00-.91.3 1.633 1.633 0 00-.528.867v3.546H.16v-5.165h.559l.009.82a1.521 1.521 0 011.456-.916.931.931 0 01.377.062z"
          data-name="Path 4"
          transform="translate(-.139 -254.183)"
        />
        <path
          d="M23.784 294.764a3.042 3.042 0 01.285-1.342 2.174 2.174 0 012.012-1.261 2.128 2.128 0 011.672.724 2.785 2.785 0 01.64 1.916v.12a3.059 3.059 0 01-.29 1.349 2.146 2.146 0 01-.814.923 2.224 2.224 0 01-1.2.325 2.13 2.13 0 01-1.668-.723 2.788 2.788 0 01-.642-1.917zm.573.157a2.344 2.344 0 00.48 1.521 1.624 1.624 0 002.512 0 2.425 2.425 0 00.48-1.569v-.109a2.64 2.64 0 00-.22-1.084 1.778 1.778 0 00-.616-.76 1.576 1.576 0 00-.91-.269 1.525 1.525 0 00-1.245.6 2.414 2.414 0 00-.482 1.568z"
          data-name="Path 5"
          transform="translate(-20.689 -254.18)"
        />
        <path
          d="M71.187 296.1a.818.818 0 00-.346-.684 2.645 2.645 0 00-1.028-.4 4.645 4.645 0 01-1.067-.334 1.423 1.423 0 01-.563-.46 1.145 1.145 0 01-.181-.66 1.23 1.23 0 01.511-1.01 2.069 2.069 0 011.309-.4 2.117 2.117 0 011.386.428 1.369 1.369 0 01.52 1.115h-.569a.927.927 0 00-.38-.755 1.722 1.722 0 00-1.867-.049.772.772 0 00-.342.65.7.7 0 00.285.6 3.013 3.013 0 001.04.39 4.947 4.947 0 011.126.377 1.469 1.469 0 01.556.482 1.326 1.326 0 01-.346 1.73 2.265 2.265 0 01-1.378.39 2.312 2.312 0 01-1.462-.437 1.343 1.343 0 01-.562-1.1h.571a.989.989 0 00.422.78 1.727 1.727 0 001.028.279 1.62 1.62 0 00.965-.26.788.788 0 00.372-.672z"
          data-name="Path 6"
          transform="translate(-59.012 -254.168)"
        />
        <path
          d="M110.915 297.433a2.682 2.682 0 01-.109-.72 1.974 1.974 0 01-.767.6 2.34 2.34 0 01-.985.213 1.73 1.73 0 01-1.205-.416 1.349 1.349 0 01-.462-1.05 1.385 1.385 0 01.628-1.193 3.03 3.03 0 011.75-.439h1.04v-.59a1.13 1.13 0 00-.341-.871 1.412 1.412 0 00-1-.319 1.56 1.56 0 00-.988.305.91.91 0 00-.39.736h-.573a1.328 1.328 0 01.573-1.067 2.21 2.21 0 011.408-.451 2.006 2.006 0 011.362.433 1.576 1.576 0 01.52 1.205v2.444a3.051 3.051 0 00.157 1.122v.057zm-1.8-.409a1.918 1.918 0 001.024-.277 1.622 1.622 0 00.656-.741v-1.136h-1.022a2.55 2.55 0 00-1.336.313.939.939 0 00-.482.833.923.923 0 00.322.72 1.213 1.213 0 00.837.287z"
          data-name="Path 7"
          transform="translate(-93.427 -254.19)"
        />
        <path
          d="M151.785 292.266l.019.883a2.048 2.048 0 01.725-.728 1.894 1.894 0 01.959-.251 1.549 1.549 0 011.236.468 2.114 2.114 0 01.411 1.4v3.389h-.568v-3.394a1.542 1.542 0 00-.294-1.031 1.156 1.156 0 00-.924-.338 1.439 1.439 0 00-.937.333 1.94 1.94 0 00-.6.894v3.537h-.568v-5.166z"
          data-name="Path 8"
          transform="translate(-131.579 -254.188)"
        />
        <path
          d="M195.527 297.433a2.62 2.62 0 01-.11-.72 1.979 1.979 0 01-.766.6 2.35 2.35 0 01-.987.213 1.73 1.73 0 01-1.205-.416 1.353 1.353 0 01-.461-1.05 1.387 1.387 0 01.627-1.193 3.029 3.029 0 011.75-.439h1.04v-.59a1.13 1.13 0 00-.341-.871 1.412 1.412 0 00-1-.319 1.56 1.56 0 00-.988.305.91.91 0 00-.39.736h-.573a1.328 1.328 0 01.573-1.067 2.21 2.21 0 011.408-.451 2.011 2.011 0 011.364.433 1.547 1.547 0 01.512 1.205v2.444a3.02 3.02 0 00.159 1.122v.057zm-1.8-.409a1.916 1.916 0 001.023-.277 1.622 1.622 0 00.65-.741v-1.136h-1.015a2.55 2.55 0 00-1.336.313.939.939 0 00-.482.833.923.923 0 00.322.72 1.214 1.214 0 00.843.287z"
          data-name="Path 9"
          transform="translate(-167.038 -254.19)"
        />
        <path
          d="M255.952 292.265l.019.84a1.907 1.907 0 01.716-.7 1.96 1.96 0 01.954-.234 1.449 1.449 0 011.552 1 1.923 1.923 0 01.74-.742 2.039 2.039 0 011.022-.26q1.661 0 1.69 1.82v3.446h-.573v-3.41a1.523 1.523 0 00-.3-1.027 1.219 1.219 0 00-.957-.334 1.521 1.521 0 00-1.04.4 1.406 1.406 0 00-.472.948v3.418h-.573v-3.446a1.407 1.407 0 00-.312-.991 1.24 1.24 0 00-.948-.328 1.44 1.44 0 00-.927.308 1.82 1.82 0 00-.573.91v3.542h-.57v-5.161z"
          data-name="Path 10"
          transform="translate(-222.198 -254.187)"
        />
        <path
          d="M323.694 297.51a2.24 2.24 0 01-1.192-.325 2.21 2.21 0 01-.828-.91 2.816 2.816 0 01-.3-1.3v-.2a3.024 3.024 0 01.29-1.34 2.3 2.3 0 01.8-.939 1.989 1.989 0 011.118-.342 1.863 1.863 0 011.491.642 2.611 2.611 0 01.553 1.755v.32h-3.687v.109a2.165 2.165 0 00.5 1.464 1.6 1.6 0 001.267.585 1.854 1.854 0 00.809-.168 1.781 1.781 0 00.638-.534l.358.273a2.081 2.081 0 01-1.817.91zm-.105-4.87a1.43 1.43 0 00-1.087.473 2.185 2.185 0 00-.537 1.269h3.1v-.061a1.907 1.907 0 00-.425-1.213 1.317 1.317 0 00-1.051-.468z"
          data-name="Path 11"
          transform="translate(-279.599 -254.171)"
        />
        <path
          d="M366.539 292.747a2.15 2.15 0 00-.39-.033 1.39 1.39 0 00-.9.3 1.633 1.633 0 00-.528.867v3.546h-.568v-5.165h.558l.01.82a1.518 1.518 0 011.456-.917.937.937 0 01.377.062z"
          data-name="Path 12"
          transform="translate(-316.811 -254.183)"
        />
        <path
          d="M390.719 278.851a.382.382 0 01.105-.273.373.373 0 01.286-.112.382.382 0 01.289.112.378.378 0 01.108.273.371.371 0 01-.108.27.39.39 0 01-.289.109.368.368 0 01-.39-.38zm.673 6.657h-.573v-5.165h.573z"
          data-name="Path 13"
          transform="translate(-339.926 -242.266)"
        />
        <path
          d="M409.883 292.266l.02.883a2.048 2.048 0 01.725-.728 1.894 1.894 0 01.959-.251 1.553 1.553 0 011.238.468 2.1 2.1 0 01.41 1.4v3.389h-.568v-3.394a1.547 1.547 0 00-.292-1.031 1.154 1.154 0 00-.924-.338 1.443 1.443 0 00-.939.333 1.938 1.938 0 00-.6.894v3.537h-.568v-5.166z"
          data-name="Path 14"
          transform="translate(-356.127 -254.188)"
        />
        <path
          d="M449.729 294.764a3.043 3.043 0 01.3-1.342 2.174 2.174 0 012.012-1.261 2.127 2.127 0 011.672.724 2.786 2.786 0 01.64 1.916v.12a3.075 3.075 0 01-.294 1.349 2.155 2.155 0 01-.814.923 2.231 2.231 0 01-1.2.325 2.129 2.129 0 01-1.669-.723 2.78 2.78 0 01-.642-1.917zm.573.157a2.345 2.345 0 00.48 1.521 1.624 1.624 0 002.512 0 2.425 2.425 0 00.48-1.569v-.109a2.62 2.62 0 00-.22-1.084 1.779 1.779 0 00-.616-.762 1.577 1.577 0 00-.91-.269 1.528 1.528 0 00-1.245.6 2.425 2.425 0 00-.482 1.568z"
          data-name="Path 15"
          transform="translate(-391.265 -254.18)"
        />
        <path
          d="M516.925 296.108a.818.818 0 00-.342-.684 2.645 2.645 0 00-1.028-.4 4.624 4.624 0 01-1.067-.334 1.43 1.43 0 01-.564-.46 1.154 1.154 0 01-.183-.659 1.23 1.23 0 01.511-1.01 2.066 2.066 0 011.308-.4 2.125 2.125 0 011.387.428 1.371 1.371 0 01.52 1.115h-.572a.93.93 0 00-.38-.755 1.716 1.716 0 00-1.863-.049.771.771 0 00-.341.65.707.707 0 00.283.6 3.041 3.041 0 001.04.39 4.939 4.939 0 011.127.377 1.468 1.468 0 01.556.482 1.33 1.33 0 01-.346 1.729 2.265 2.265 0 01-1.378.39 2.313 2.313 0 01-1.464-.437 1.346 1.346 0 01-.56-1.1h.576a.989.989 0 00.423.78 1.726 1.726 0 001.028.28 1.622 1.622 0 00.965-.26.79.79 0 00.364-.673z"
          data-name="Path 16"
          transform="translate(-446.806 -254.177)"
        />
        <path
          d="M559.3 294.9a3.111 3.111 0 01-.536 1.917 1.7 1.7 0 01-1.43.708 1.912 1.912 0 01-1.624-.745v2.635h-.568v-7.15h.53l.029.731a1.831 1.831 0 011.618-.827 1.719 1.719 0 011.453.7 3.2 3.2 0 01.528 1.95zm-.573-.1a2.65 2.65 0 00-.406-1.56 1.308 1.308 0 00-1.132-.573 1.507 1.507 0 00-1.479.989v2.478a1.519 1.519 0 00.588.677 1.69 1.69 0 00.9.234 1.3 1.3 0 001.125-.576 2.9 2.9 0 00.401-1.669z"
          data-name="Path 17"
          transform="translate(-482.973 -254.188)"
        />
        <path
          d="M595.927 294.764a3.043 3.043 0 01.289-1.342 2.174 2.174 0 012.012-1.261 2.128 2.128 0 011.672.724 2.785 2.785 0 01.64 1.916v.12a3.059 3.059 0 01-.29 1.349 2.146 2.146 0 01-.814.923 2.223 2.223 0 01-1.2.325 2.129 2.129 0 01-1.669-.723 2.789 2.789 0 01-.642-1.917zm.573.157a2.344 2.344 0 00.48 1.521 1.624 1.624 0 002.512 0 2.45 2.45 0 00.475-1.573v-.109a2.642 2.642 0 00-.22-1.084 1.779 1.779 0 00-.616-.762 1.577 1.577 0 00-.91-.269 1.525 1.525 0 00-1.245.6 2.415 2.415 0 00-.482 1.568z"
          data-name="Path 18"
          transform="translate(-518.458 -254.18)"
        />
        <path
          d="M644.384 292.747a2.147 2.147 0 00-.39-.033 1.4 1.4 0 00-.91.3 1.632 1.632 0 00-.528.867v3.546H642v-5.165h.559l.009.82a1.519 1.519 0 011.456-.916.93.93 0 01.377.062z"
          data-name="Path 19"
          transform="translate(-558.541 -254.183)"
        />
        <path
          d="M667.272 282.74v1.322h1.069v.468h-1.069v3.444a1.074 1.074 0 00.155.64.6.6 0 00.52.211 3.466 3.466 0 00.463-.048l.023.464a1.875 1.875 0 01-.611.081 1.018 1.018 0 01-.854-.342 1.606 1.606 0 01-.268-1v-3.45h-.95v-.468h.95v-1.322z"
          data-name="Path 20"
          transform="translate(-579.204 -245.984)"
        />
        <path
          d="M697.643 296.1a.816.816 0 00-.341-.684 2.643 2.643 0 00-1.03-.4 4.634 4.634 0 01-1.066-.334 1.429 1.429 0 01-.564-.46 1.154 1.154 0 01-.183-.66 1.23 1.23 0 01.511-1.01 2.065 2.065 0 011.308-.4 2.125 2.125 0 011.387.428 1.372 1.372 0 01.52 1.115h-.573a.927.927 0 00-.38-.755 1.714 1.714 0 00-1.862-.049.772.772 0 00-.342.65.7.7 0 00.285.6 3.007 3.007 0 001.04.39 4.963 4.963 0 011.127.377 1.463 1.463 0 01.555.482 1.326 1.326 0 01-.346 1.729 2.259 2.259 0 01-1.377.39 2.313 2.313 0 01-1.464-.437 1.341 1.341 0 01-.56-1.105h.572a.994.994 0 00.422.78 1.733 1.733 0 001.03.279 1.623 1.623 0 00.965-.26.789.789 0 00.366-.666z"
          data-name="Path 21"
          transform="translate(-604.033 -254.168)"
        />
      </g>
    </svg>
  );
}

export default Logo;
