import styled, {css, keyframes} from "styled-components"
import {Link} from "gatsby"
import media from "styled-media-query"

import {Menu as MenuIcon} from '@styled-icons/entypo/Menu'
import { CloseOutline as CloseIcon} from '@styled-icons/evaicons-outline/CloseOutline'

type HeaderProps = {
  position: number
  active: boolean
  isOpen: boolean
}

const linkModifiers = {
  home: () => css`
    width: 115px;
    left: 0;
  `,
  who: () => css`
    width: 105px;
    left: 195px;
  `,
  modal: () => css`
    width: 105px;
    left: 335px;
  `,
  training: () => css`
    width: 65px;
    left: 469px;
  `,
  coach: () => css`
    width: 55px;
    left: 566px;
  `,
  plans: () => css`
    width: 60px;
    left: 655px;
  `,
  contacts: () => css`
    width: 65px;
    left: 745px;
  `,
}

export const Wrapper = styled.div`
  ${({theme}) => css`
    position: fixed;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    height: 70px;
    padding: 0;
    background-color: ${theme.colors.secondary};
    box-shadow: 3px 2px 5px #00000029;
  `}
`

export const WrapperContent = styled.div.attrs(() => ({
  className: 'container',
}))`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 1100px !important;
  height: 70px;

  ${media.lessThan("medium")`
    max-width: 100%!important;
    justify-content: center;
    
    svg {
    margin-top: 5px;
      width: 85%;
    }
  `}

  ${media.lessThan("large")`
    max-width: 880px!important;
  `}

`

type ActiveLinkProps = {
  position: number
}

export const ActiveLink = styled.div<ActiveLinkProps>`
  ${({theme, position}) => css`
    position: absolute;
    z-index: 2;
    bottom: 0;
    height: 6px;
    background-color: ${theme.colors.primary};
    border-radius: 10px 10px 0 0;
    transition: width 0.5s, left 0.5s;
    
    ${position >= 0 && position < 800 && linkModifiers.home()};
    ${position > 800 && position < 2000  && linkModifiers.who()};
    ${position > 2000 && position < 2800  && linkModifiers.modal()};
    ${position > 2800 && position < 3900  && linkModifiers.training()};
    ${position > 3900 && position < 5500  && linkModifiers.coach()};
    ${position > 5500 && position < 8600  && linkModifiers.plans()};
    ${position > 8600 && linkModifiers.contacts()};

    ${media.lessThan("medium")`
      display: none;
    `}

    ${media.lessThan("large")`
       width: 10%;
    `}
  `}
`

export const Menu = styled.div`
  ${({theme}) => css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
    width: 65%;
    height: 50px;
    letter-spacing: -1px;
    margin-left: 30px;
    padding-top: 15px;
    font-family: ${theme.font.family};

    ${media.lessThan("large")`
       width: 80%;
       margin-left: 15px;
    `}
    ul {
      width: 100%;
      list-style: none;
      text-align: left;

      li {
        display: inline;
        margin-left: 44px;
        position: relative;

        a {
          position: relative;
          font-size: 18px;
          color: ${theme.colors.white};
          text-decoration: none;
          transition: color 0.2s ease;

          &:hover {
            color: ${theme.colors.primary};

            &:before {
              width: 100%;
              height: 1px;
              content: "";
              position: absolute;
              z-index: 2;
              bottom: -22px;
              background-color: ${theme.colors.primary};
              border-radius: 10px;
            }


          }
        }

        ${media.lessThan("large")`
          margin-left: 30px;
          a {
            font-size: ${theme.font.sizes.medium};
            
          }
        `}

      }
    }

    ${media.lessThan("large")`
      display: none;
    `}
  `}
`

export const ButtonWrapper = styled.div`
  a {
    text-decoration: none;
  }
  ${media.lessThan("large")`
      display: none;
  `}
`

// MOBILE MENU
// ANIMATIONS
const rotateOpen = keyframes`
  from {
    transform: rotate(0deg);
    opacity: 1;
  }

  to {
    transform: rotate(90deg);
    opacity: 0;
  }
`

const rotateClose = keyframes`
  from {
    transform: rotate(90deg);
    opacity: 0;
  }

  to {
    transform: rotate(0deg);
    opacity: 1;
  }
`

export const MobileWrapper = styled.div`
  ${({active}: HeaderProps) => css`
    position: relative;
    z-index: 15;
    width: 10vw;
    min-height: 100%;
    display: ${active ? "block" : "none"};
  `}
`

export const WrapperLogo = styled.div`
  ${({isOpen}: HeaderProps) => css`
    position: absolute;
    z-index: 210;
    top: 30px;
    left: 30px;
    transform: translateX(${isOpen ? "30vw" : "-100vw"});
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

    svg {
      margin-top: 5px;
      width: 100%;
    }
  `}
`

export const MobileMenu = styled(MenuIcon)`
  ${({position, active}: HeaderProps) => css`
    width: 42px !important;
    height: 42px;
    color: #4DC2B3;
    position: fixed;
    z-index: 9999;
    top: 10px;
    left: 16px;
    display: ${active ? "none" : "block"};

    &:active,
    &:hover {
      cursor: pointer;
      color: #638db3;
    }
  `}

  ${media.greaterThan("medium")`
    display: none;
  `};
`

export const CloseMenu = styled(CloseIcon)`
  ${({isOpen}: HeaderProps) => css`
    width: 42px!important;
    height: 42px;
    color: #4DC2B3;
    position: fixed;
    z-index: 9999;
    top: 25px;
    right: 20px;
    transform: translateX(${isOpen ? "0" : "-100vw"});
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    animation-direction: reverse;

    &:active,
    &:hover {
      cursor: pointer;
    }

    ${media.greaterThan("medium")`
      display: none;
    `};
  `}
`

export const MenuIn = styled.div`
  ${({isOpen}: HeaderProps) => css`
    position: fixed;
    z-index: 100;
    width: 100vw;
    min-height: 100%;
    padding: 30px;
    top: 0;
    left: 0;
    background-color: #f5f6fa;
    -webkit-font-smoothing: antialiased;
    transform: translateX(${isOpen ? "0" : "-100vw"});
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

    ${media.lessThan("medium")`
      padding-top: 100px;
    `};
  `}
`

export const MenuList = styled(Link)`
  display: block;
  
  font-size: 2em;
  font-weight: 700;
  line-height: 2.5em;
  letter-spacing: -1px;
  color: #4DC2B3;
  margin-left: 12px;
  text-decoration: none;

`
