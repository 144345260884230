import React from 'react'
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

import Count from './count'

import * as S from './styled'

const CountComponent = () => {
  return (
    <S.Wrapper>
      <S.WrapperArcos>
        <S.Content>
          <S.Image/>
          <S.Numbers>
            <S.ListNumbers>
              <CountUp end={7} duration={2} redraw={true}>
                {({countUpRef, start}) => (
                  <>
                    <VisibilitySensor onChange={start} delayedCall>
                      <span ref={countUpRef}/>
                    </VisibilitySensor>
                    <S.ListNames>países</S.ListNames>
                  </>
                )}
              </CountUp>
            </S.ListNumbers>
            <S.ListNumbers>
              <CountUp end={300} duration={2} redraw={true}>
                {({countUpRef, start}) => (
                  <>
                    <span style={{ paddingRight: '15px' }}>+</span>
                    <VisibilitySensor onChange={start} delayedCall>
                      <span ref={countUpRef}/>
                    </VisibilitySensor>
                    <S.ListNames>atletas</S.ListNames>
                  </>
                )}
              </CountUp>
            </S.ListNumbers>
          </S.Numbers>
        </S.Content>
      </S.WrapperArcos>
    </S.Wrapper>
  );
};

export default CountComponent;
