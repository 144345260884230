import * as React from "react"

import Layout from '../components/Layout/layout'

import 'bootstrap/dist/css/bootstrap.min.css';
import Menu from "../components/Menu";
import Hero from "../components/Hero"
import Whowe from "../components/Whowe"
import Modality from "../components/Modality"
import Training from "../components/Training"
import Phrase from "../components/Phrase"
import Count from "../components/Count"
import Plans from "../components/Plans";
import PlanItems from "../components/PlanItems";
import Partners from "../components/Partners";
import Contact from "../components/Contact";

const IndexPage = () => {
  return (
    <Layout>
      <Menu />
      <Hero />
      <Whowe />
      <Modality />
      <Training />
      <Phrase />
      <Count />
      <Plans />
      <PlanItems />
      <Partners />
      <Contact />
    </Layout>
  )
}

export default IndexPage
