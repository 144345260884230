import styled, {css} from "styled-components"
import media, {generateMedia} from 'styled-media-query'

import Lions from '../../images/lions.png'

const customMedia = generateMedia({
  wide: '1441px',
});

export const Wrapper = styled.div`
  ${({theme}) => css`
    width: 100%;
    height: 100%;
    min-height: 730px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${theme.colors.mainBg};
    background-image: url(${Lions});
    background-repeat: no-repeat;
    background-position: right 50px center;
    background-size: 22%;

    ${customMedia.greaterThan("wide")`
        background-size: min(50%, 300px);
        background-repeat: no-repeat;
        background-position: right 15% center;
    `}

    ${media.lessThan("large")`
      height: 100%;
      min-height: 630px;
      background-position: right 25px center;
      background-size: 25%;
    `}

    ${media.lessThan("medium")`
      justify-content: flex-start;
      background-image: none;
    `}

  `}
`

export const Content = styled.div.attrs(() => ({
  className: 'container',
}))`
  max-width: 1100px !important;

  ${media.lessThan("large")`
      max-width: 880px !important;
  `}

  ${media.lessThan("medium")`
      padding-top: 20px !important;
      width: 95% !important;
  `}
`

export const Phrase = styled.div`
  ${({theme}) => css`
    width: 80%;
    font-family: ${theme.font.family};
    font-weight: ${theme.font.ultra};
    font-size: 5.6rem;
    line-height: 7.4rem;
    letter-spacing: 0;
    color: ${theme.colors.white};
    margin-bottom: 24px;

    ${media.lessThan("large")`
      width: 72%;
      font-size:  4.7rem;
      line-height:  5.2rem;
    `}

    ${media.lessThan("medium")`
      width: 100%;
      font-size: 3.7rem;
      line-height: 4.6rem;
    `}

  `}
`

export const Author = styled.div`
  ${({theme}) => css`
    font-family: ${theme.font.family};
    font-weight: ${theme.font.ultra};
    font-size: ${theme.font.sizes.xlarge};
    line-height: ${theme.font.sizes.xlarge};
    letter-spacing: 0;
    color: ${theme.colors.altBg};

    ${media.lessThan("medium")`
      font-size: 2.5rem;
    `}

  `}
`

export const MoreContent = styled.div`
  ${({theme}) => css`
    display: flex;
    flex-direction: column;
    margin-top: 55px;

    & h2 {
      font-family: ${theme.font.family};
      font-weight: ${theme.font.top};
      font-size: ${theme.font.sizes.large};
      line-height: ${theme.font.sizes.xlarge};
      color: ${theme.colors.darkGray};
      text-transform: uppercase;
    }

    ${media.lessThan("medium")`
      margin-top: 35px;
    `}

  `}

`

export const Midia = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  position: relative;
  z-index: 1;

  ${media.lessThan("medium")`
    flex-wrap: wrap;
    margin-bottom: 30px;
  `}
`

export const Images = styled.div`
  ${({theme}) => css`
    width: 87px;
    height: 87px;
    position: relative;
    z-index: 5;
    margin-right: 25px;

    ${media.lessThan("large")`
      width: 75px;
      height: 75px;
      margin-right: 20px;
    `}
    
    ${media.lessThan("medium")`
      width: 80px;
      height: 80px;
      margin-right: 28px;
      margin-bottom: 18px;
    `}
    
    & img {
      width: 87px;
      height: 87px;
      border-radius: 8px;
      border: 3px solid ${theme.colors.primary};

      ${media.lessThan("large")`
        width: 75px;
        height: 75px;
      `}

      ${media.lessThan("medium")`
        width: 90px;
        height: 90px;
      `}

    }

    & div {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 1;
      top: 0;
      background-color: rgba(0, 0, 0, 0.7);
      width: 87px;
      height: 87px;
      border-radius: 8px;
      transition: all 0.1s ease-out;

      ${media.lessThan("large")`
        width: 75px;
        height: 75px;
      `}
      ${media.lessThan("medium")`
        width: 90px;
        height: 90px;
      `}
      & > img {
        width: 50px;
        height: 50px;
        border: none;

        ${media.lessThan("large")`
          width: 38px;
          height: 38px;
        `}

        ${media.lessThan("medium")`
          width: 50px;
          height: 50px;
        `}

      }

      &:hover {
        background-color: transparent;

        & > img {
          display: none;
        }
      }
    }
  `}
`
