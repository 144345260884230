import React from 'react'

import * as S from './styled'

const PartnersComponent = () => {

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>Parceiros</S.Title>
        <S.ContentPartners>
          <S.SpeedoPartner />
          <S.AtilattePartner />
          <S.PadocaPartner />
          <S.EuroPartner />
        </S.ContentPartners>
      </S.Content>
    </S.Wrapper>
  );
};

export default PartnersComponent;
