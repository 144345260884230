import React from 'react'

import Button from '../Button'
import IconCheck from '../Icons/Check'

import * as S from './styled'
import {Link} from "gatsby";

const PlansComponent = () => {

  const itemsGold = [
    {
      title: 'Treinos estruturados via Training Peaks',
      iconColor: '#FA793F'
    },
    {
      title: 'Treinos personalizados e individualizados',
      iconColor: '#FA793F'
    },
    {
      title: 'Perfil Training Peaks - Conta básica',
      iconColor: '#FA793F'
    },
    {
      title: 'Participação em todos os treinos coletivos',
      iconColor: '#FA793F'
    },
    {
      title: 'Análise do PMC (Carga de treino)',
      iconColor: '#717171'
    },
    {
      title: 'Feedback dos principais treinos prescritos',
      iconColor: '#717171'
    },
    {
      title: 'Estratégia de prova e avaliação pós prova',
      iconColor: '#717171'
    },
    {
      title: 'Contato ilimitado com treinador',
      iconColor: '#717171'
    },
    {
      title: 'Kit RM (uniforme) a cada 6 meses',
      iconColor: '#717171'
    },
    {
      title: 'Reunião por vídeo sempre que solicitado',
      iconColor: '#717171'
    },
  ]

  const itemsPremium = [
    {
      title: 'Treinos estruturados via Training Peaks',
      iconColor: '#00B2A9'
    },
    {
      title: 'Treinos personalizados e individualizados',
      iconColor: '#00B2A9'
    },
    {
      title: 'Perfil Training Peaks - Conta premium',
      iconColor: '#00B2A9'
    },
    {
      title: 'Participação em todos os treinos coletivos',
      iconColor: '#00B2A9'
    },
    {
      title: 'Análise do PMC (Carga de treino)',
      iconColor: '#00B2A9'
    },
    {
      title: 'Feedback dos principais treinos prescritos',
      iconColor: '#00B2A9'
    },
    {
      title: 'Estratégia de prova e avaliação pós prova',
      iconColor: '#00B2A9'
    },
    {
      title: 'Contato ilimitado com treinador',
      iconColor: '#00B2A9'
    },
    {
      title: 'Kit RM (uniforme) a cada 6 meses',
      iconColor: '#00B2A9'
    },
    {
      title: 'Reunião por vídeo sempre que solicitado',
      iconColor: '#00B2A9'
    },
  ]

  return (
    <S.Wrapper id="planos">
      <S.Content>
        <S.Title>Planos</S.Title>
        <S.BoxPlans>
          <S.Box lado="true">
            <S.BoxTitle lado="true">Gold</S.BoxTitle>
            <S.BoxContent>
              {itemsGold.map((item, index) => {
                return (<S.BoxList key={index} color={item.iconColor}>
                    <IconCheck color={item.iconColor}/><span>{item.title}</span></S.BoxList>
                )
              })}
            </S.BoxContent>
            <S.BoxButton>
              <Link to="#contato">
                <Button>Entre para o time!</Button>
              </Link>
            </S.BoxButton>
          </S.Box>
          <S.Box>
            <S.BoxTitle>Premium</S.BoxTitle>
            <S.BoxContent>

              {itemsPremium.map((item, index) => {
                return (<S.BoxList key={index} color={item.iconColor}>
                    <IconCheck/><span>{item.title}</span></S.BoxList>
                )
              })}
            </S.BoxContent>
            <S.BoxButton>
              <Link to="#contato">
                <Button size="true" color="true">Entre para o time!</Button>
              </Link>
            </S.BoxButton>
          </S.Box>
        </S.BoxPlans>
        <S.BoxPlans>
          <S.BoxPremium>
            <S.ArrowPremium />
            <h1>Plano Premium</h1>
            <h2>Criamos um <strong>serviço diferenciado</strong> para atender quem busca grandes resultados e mais
              qualidade na sua prática esportiva. Acreditamos que treinos com dados é tornar seu treino mais eficiente.
              Com base no seu <strong>histórico de treinos</strong>, estabelecemos seu perfil de habilidades e condicionamento, aplicando
              <strong> treinos específicos</strong> com metas claramente definidas e zonas de treinamento que lhe permitirão tirar o
              máximo proveito do seu treino.</h2>
          </S.BoxPremium>
        </S.BoxPlans>
      </S.Content>
    </S.Wrapper>
  );
};

export default PlansComponent;
