import React from 'react'
import {Link} from 'gatsby'
import {StaticImage} from "gatsby-plugin-image"

import Logo from './../Logo'

import IconInsta from "./IconInsta";
import IconFacebook from "./IconFacebook";

import * as S from './styled'

const ContactComponent = () => {
    return (
      <S.Wrapper id="contato">
        <S.Container>
          <S.Content>
            <S.Title>Contato</S.Title>
            <S.Description>
              <p>Entre em contato com a nossa equipe, teremos o maior prazer em atender.</p>
              <S.BoxList>
                <StaticImage src="../../images/icon-email.svg" alt="link para e-mail"/>
                <span><a href="mailto:rosanamerinosports@gmail.com">rosanamerinosports@gmail.com</a></span>
              </S.BoxList>
              <S.BoxList>
                <StaticImage src="../../images/icon-tel.svg" alt="nosso telefone (19) 99910-0648"/>
                <span>(19) 99910-0648</span>
              </S.BoxList>
              <S.BoxList>
                <StaticImage src="../../images/pinned.svg" alt="nossa localização"/>
                <span>Campinas/SP</span>
              </S.BoxList>
            </S.Description>
          </S.Content>
          <S.WrapperFooter>
            <S.Menu centered grow={2}>
              <Link to="/">
                <Logo/>
              </Link>
            </S.Menu>
            <S.ViewMenuDesktop>
              <S.Menu>
                <ul>
                  <li><Link to="/">Home</Link></li>
                  <li><Link to="#quem-somos">Quem Somos</Link></li>
                  <li><Link to="#modalidades">Modalidades</Link></li>
                </ul>
              </S.Menu>
              <S.Menu>
                <ul>
                  <li><Link to="#treinos">Treinamentos</Link></li>
                  <li><Link to="/">Contato</Link></li>
                </ul>
              </S.Menu>
              <S.Menu grow={2}>
                <ul>
                  <li>rosanamerinosports@gmail.com</li>
                  <li>(19) 99910-0648</li>
                  <li>Campinas/SP para o mundo!</li>
                </ul>
              </S.Menu>
            </S.ViewMenuDesktop>
            <S.Menu>
              <div>Siga nosso team!</div>
              <span>
              <a href="https://www.instagram.com/rosanamerinosports" target="_blank" rel="noopener noreferrer">
                <IconInsta/>
              </a>
              <a href="https://www.facebook.com/rosanamerinosports" target="_blank" rel="noopener noreferrer">
              <IconFacebook/>
              </a>
              </span>
            </S.Menu>
          </S.WrapperFooter>
          <S.ViewMenuMobile>
            <S.Menu>
              <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="#quem-somos">Quem Somos</Link></li>
                <li><Link to="#modalidades">Modalidades</Link></li>
                <li><Link to="#treinos">Treinamentos</Link></li>
                <li><Link to="#contato">Contato</Link></li>
                <li>rosanamerinosports@gmail.com</li>
                <li>(19) 99910-0648</li>
                <li>Campinas/SP para o mundo!</li>
              </ul>
            </S.Menu>
          </S.ViewMenuMobile>
          <S.CopyRight>Copyright {new Date().getFullYear().toString()} © Rosana Merino</S.CopyRight>
        </S.Container>
      </S.Wrapper>
    );
  }
;

export default ContactComponent;
