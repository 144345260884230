import styled, {css} from "styled-components"
import media, {generateMedia} from 'styled-media-query'

import bullet from '../../images/who-bullet.png'
import ArcoSuperior from '../../images/arco-superior.svg'


const customMedia = generateMedia({
  desktop: '1280px',
  large: '1170px'
});

export const Wrapper = styled.div`
  ${({theme}) => css`
    width: 100%;
    height: 800px;
    min-height: 1250px;
    background-image: url(${ArcoSuperior});
    background-repeat: no-repeat;
    background-position: bottom -1px center;
    background-size: 110%;
    padding-bottom: 30px;

    ${media.lessThan("medium")`
      height: 800px;
      min-height: 1700px!important;
      margin-top: -30px;
    `}

    ${media.lessThan("large")`
      min-height: 1350px;
    `}
  `}
`

export const Title = styled.div`
  ${({theme}) => css`
    font-family: ${theme.font.family};
    font-weight: ${theme.font.ultra};
    font-size: ${theme.font.sizes.huge};
    line-height: ${theme.font.sizes.xlarge};
    letter-spacing: 0px;
    color: ${theme.colors.white};
    text-transform: uppercase;
    text-align: center;
    padding: 25px 0;

    ${media.lessThan("large")`
      font-size: ${theme.font.sizes.xxlarge};
    `}

  `}
`

export const TimelineContainer = styled.div`
  ${({theme}) => css`
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    margin: 40px 0 10px;

    &::after {
      background-color: ${theme.colors.white};
      content: '';
      position: absolute;
      left: calc(50% - 2px);
      width: 4px;
      height: 95%;
    }

    &::before {
      content: ' ';
      background-image: url(${bullet});
      position: absolute;
      z-index: 2;
      left: calc(50% - 20px);
      bottom: 20px;
      width: 39px;
      height: 39px;
    }

    ${media.lessThan("medium")`
      &::after {
          left: 35px;
          width: 4px;
          height: 105%;
      }
      
      &::before {
          left: 20px;
          bottom: -40px;
          width: 39px;
          height: 39px;
      }
      
    `}

  `}

`

export const TimeLineContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  width: 100%;
  max-width: 60%;
  text-align: right;
  padding: 5px;
  margin-bottom: 15px;

  ${media.lessThan("medium")`
    width: 100%;
    max-width: 100%;
    text-align: left!important;
    margin-bottom: 30px;
  `}
`

export const TimeLineItem = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  position: relative;
  width: 50%;

  ${media.lessThan("medium")`
     width: 86%;
     padding-right: 0;
     margin-left: 28px;
     
  `}
  &:nth-child(odd) {
    align-self: flex-start;
    justify-content: flex-end;
    padding-left: 0;
    padding-right: 30px;

    &::after {
      content: ' ';
      background-image: url(${bullet});
      position: absolute;
      z-index: 2;
      top: -5px;
      right: -20px;
      transform: rotate(45deg);
      width: 39px;
      height: 39px;
    }

    ${media.lessThan("medium")`
        padding-left: 35px;
        padding-right: 0;
      
        &::after {
          left: -8px;
      }
  `}

  }

  &:nth-child(even) {
    align-self: flex-end;
    justify-content: flex-start;
    padding-left: 30px;
    padding-right: 0;
    margin-top: 10px;

    &::after {
      content: ' ';
      background-image: url(${bullet});
      position: absolute;
      z-index: 2;
      top: -5px;
      left: -20px;
      transform: rotate(45deg);
      width: 39px;
      height: 39px;
    }

    ${media.lessThan("medium")`
        align-self: flex-start;
        justify-content: flex-start;
        padding-left: 35px;
        padding-right: 0;
        margin-top: 10px;
    
        &::after {
          top: -5px;
          left: -8px;
        }
   
   `}

  }

  &:nth-child(odd) ${TimeLineContent} {
    text-align: right;
    align-items: flex-start;
  }

  &:nth-child(even) ${TimeLineContent} {
    text-align: left;
    align-items: flex-start;
  }

`

export const TimeLineTitle = styled.h1`
  ${({theme}) => css`
    width: 100%;
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.xlarge};
    font-weight: ${theme.font.bold};
    line-height: ${theme.font.sizes.xlarge};
    color: ${theme.colors.primary};
    text-transform: uppercase;
    margin-bottom: 15px;
  `}

`

export const TimeLineText = styled.p`
  ${({theme}) => css`
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.normal};
    line-height: ${theme.font.sizes.xlarge};
    color: ${theme.colors.white};
    letter-spacing: 0;
  `}

`


export const CoachContent = styled.div.attrs(() => ({
  className: 'container',
}))`
  width: 70% !important;
  max-width: 1100px !important;
  height: 350px;
  max-height: 400px;
  padding: 20px 0 !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  ${media.lessThan("large")`
    max-width: 880px!important;
  `}

  ${media.lessThan("medium")`
    width: 93% !important;
    max-width: 100% !important;
    flex-direction: column;
  `}
`

export const CoachPicture = styled.div`
  width: 32%;
  margin-right: 24px;

  & img {
    width: 309px;
    max-width: 100%;
  }

  ${media.lessThan("medium")`
      width: 100%;
      text-align: center;
      margin-right: 0;
      margin-top: -30px;
            
      & img {
        width: 100%;
      }
  `}

  ${customMedia.between('large', 'desktop')`
    width: 30%;
  `}
`

export const CoachText = styled.div`
  ${({theme}) => css`
    width: 68%;

    & h2 {
      font-family: ${theme.font.family};
      font-weight: ${theme.font.top};
      font-size: ${theme.font.sizes.xxlarge};
      line-height: ${theme.font.sizes.huge};
      letter-spacing: 0;
      color: ${theme.colors.primary};
      text-transform: uppercase;
      margin-bottom: ${theme.spacings.xxsmall}
    }

    & p {
      font-family: ${theme.font.family};
      font-weight: ${theme.font.normal};
      font-size: ${theme.font.sizes.medium};
      line-height: ${theme.font.sizes.xlarge};
      letter-spacing: 0;
      color: ${theme.colors.white};
      margin-bottom: ${theme.spacings.small}
    }

    ${media.lessThan("medium")`
      width: 95%;
      & h2 {
        font-size: 2.4rem;
      }
    `}

  `}

`
