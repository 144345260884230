import styled, {css} from 'styled-components';
import media, {generateMedia} from 'styled-media-query'

import GeneralBG from '../../images/bg-souloflion.png'

const customMedia = generateMedia({
  wide: '1441px',
  desktop: '1367px'
});

export const Wrapper = styled.div`
    background-color: #464646;
    background-image: url(${GeneralBG});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top 290px center;
    background-attachment: fixed;

    ${customMedia.greaterThan('wide')`
      background-position: top 370px center;
    `}

    ${customMedia.between('desktop', 'wide')`
      background-position: top 400px center;
    `}

    ${media.lessThan('large')`
      background-position: top 340px center;
    `}

    ${media.lessThan("medium")`
      background-position: top 510px center!important;
    `}

`
