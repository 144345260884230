import styled, {css} from "styled-components"
import media from 'styled-media-query'

import ArcoSuperior from '../../images/arco-superior.svg'
import ArcoInferior from '../../images/arco-inferior.svg'
import Lion from '../../images/lion.png'

export const Wrapper = styled.div`
  ${({theme}) => css`
    width: 100%;
    height: 100%;
    min-height: 900px;

    ${media.lessThan("large")`
        min-height: 700px;
    `}

    ${media.lessThan("medium")`
        min-height: 700px;
    `}
    
  `} 
`

export const WrapperArcos = styled.div`
  ${({theme}) => css`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: inherit;
    background-image: url(${ArcoSuperior}), url(${ArcoInferior});
    background-repeat: no-repeat;
    background-position: bottom -1px center, top 0 center;
    background-size: 110%;

    ${media.lessThan("large")`
        background-position: bottom -1px center, top center;
    `}

    ${media.lessThan("medium")`
        background-position: bottom -1px center, top 0 center;
        background-size: 250%;
    `}
  `}
`

export const Content = styled.div.attrs(() => ({
  className: 'container',
}))`
  max-width: 1100px !important;
  height: 100%;
  min-height: 400px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  ${media.lessThan("large")`
      max-width: 880px !important;
  `}

  ${media.lessThan("large")`
      position: relative;
      flex-direction: column;
      min-height: 480px;
  `}
`

export const Image = styled.div`
  ${({theme}) => css`
    width: 50%;
    max-width: 531px;
    height: 100%;
    min-height: 465px;
    background-image: url(${Lion});
    
    ${media.lessThan("large")`
        width: 50%;
        max-width: 450px;
        background-size: 90%;
        background-repeat: no-repeat;
        background-position: center;
    `}

    ${media.lessThan("medium")`
        position: absolute;
        z-index: 1;
        left: -50px;
        width: 100%;
        max-width: 95% !important;
        min-height: 490px;
        background-size:cover;
        background-repeat: no-repeat;
        background-position: right;
    `}
  `}

`

export const Numbers = styled.div`
  ${({theme}) => css`
    width: 50%;
    max-width: 531px;
    height: 100%;
    min-height: 465px;
    display: flex;
    padding-top: 90px;
    justify-content: space-around;
    align-items: flex-start;
    text-align: center;

    ${media.lessThan("medium")`
        position: absolute;
        z-index: 10;
        width: 95%;
        padding-top: 0;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    `}
    
  `}

`

export const ListNumbers = styled.div`
  ${({theme}) => css`
    height: 100px;
    font-family: ${theme.font.family};
    font-weight: ${theme.font.ultra};
    font-size: 100px;
    letter-spacing: 0;
    color: ${theme.colors.primary};
    text-shadow: 0 4px 24px #00B2A966;
    
    ${media.lessThan("medium")`
        margin-bottom: 120px;
    
    `}
  `}
`

export const ListNames = styled.div`
  ${({theme}) => css`
    height: 100px;
    font-family: ${theme.font.family};
    font-weight: ${theme.font.ultra};
    font-size: ${theme.font.sizes.xxlarge};
    letter-spacing: 0;
    color: ${theme.colors.white};
    text-transform: uppercase;

    ${media.lessThan("medium")`
        margin-top: -20px;
    
    `}
  `}
`
