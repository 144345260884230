import React from 'react'

import * as S from './styled'

export type ButtonComponentProps = {
  children: string | React.ReactNode
  size?: boolean | string
  color?: boolean | string
}

const ButtonComponent = ({children, size, color}: ButtonComponentProps) => {
  return (
    <S.Wrapper size={size} color={color}>
      {children}
    </S.Wrapper>
  );
};

export default ButtonComponent;
