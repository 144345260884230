import React, {useEffect, useState} from 'react'
import {Link} from 'gatsby'

import Logo from './../Logo'
import Button from '../Button'
import MenuMobile from './mobile'

import * as S from './styled'

const MenuComponent = () => {

  const [position, setPosition] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  })

  const handleScroll = () =>{
    if (window.scrollY > 0) {
      setPosition(window.scrollY)
    }else{
      setPosition(0)

    }
  }

  return (
    <S.Wrapper>
      <S.WrapperContent>
        <MenuMobile />
        <Link to="/">
          <Logo/>
        </Link>
        <S.Menu>
          <ul>
            <li>
              <Link to="#quem-somos">quem somos</Link>
            </li>
            <li>
              <Link to="#modalidades">modalidades</Link>
            </li>
            <li>
              <Link to="#treinos">treinos</Link>
            </li>
            <li>
              <Link to="#coach">coach</Link>
            </li>
            <li>
              <Link to="#planos">planos</Link>
            </li>
            <li>
              <Link to="#contato">contato</Link>
            </li>
          </ul>
        </S.Menu>
        <S.ActiveLink position={position}/>
        <S.ButtonWrapper>
          <Link to="#contato">
            <Button color="true">Entre para o time!</Button>
          </Link>
        </S.ButtonWrapper>
      </S.WrapperContent>
    </S.Wrapper>
  );
};

export default MenuComponent;
