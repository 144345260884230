import React, {useState} from 'react'
import {StaticImage} from "gatsby-plugin-image"

import * as S from './styled'

import Testimony1 from '../../images/testimony-1.jpg'
import Testimony2 from '../../images/testimony-2.jpg'

import CarouselTestimony from "./CarouselTestimony";

export type UserTestimonyProps = {
  image: string
  name: string
  status: string
  text: string
}

const testimony: UserTestimonyProps[] = [
  {
    image: Testimony1,
    name: 'Débora Guimarães',
    status: 'ATLETA E ESPOSA DO FELIPE TURINI',
    text: 'Venho mais uma vez parabenizar à vocês por todo empenho e dedicação no trabalho de preparar e construir o Felipe atleta. No domingo, ele superou todos os limites que poderia da forma mais leve dentro das possibilidades que tinha e isso somente foi possível graças aos treinos, diálogos e aconselhamentos de vocês. Ademais o Ironman deu a ele um novo significado sobre a vida. Desejo que vocês continuem a despertar na vida de outras pessoas e de outras famílias o sentimento indescritível que vivenciamos.'
  },
  {
    image: Testimony2,
    name: 'Luis Tinello',
    status: 'HÁ 16 ANOS NA RM SPORTS',
    text: 'Na RM Sports não foi somente o aprendizado sobre o esporte Triathlon mas sobre a vida, elas tem uma capacidade de ler as necessidades de cada um, é incrível a sensibilidade delas, não é à toa que estou ha 16 anos. Recomendo a todos que buscam profissionalismo e acima de tudo pessoas que amam o que fazem, venham para a RM Sports, aqui vocês irão encontrar um ambiente saudável e profissional e de alta dedicação das Coaches seja para um iniciante ou para um atleta avancado.'
  },
]

const PlanItems = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <S.Wrapper>
      <S.WrapperModal modal={showModal} onClick={() => setShowModal(false)}>
        <StaticImage src="../../images/structure.png" alt="Estrutura da equipe Rosana Merino"/>
      </S.WrapperModal>
      <S.WrapperArcos>
        <S.Content>
          <S.ColumnTextUniform>
            <S.BoxTextUniform>
              <h1>Uniformes exclusivos</h1>
              <h2>Design desenvolvido especialmente para alunos RM.</h2>
              <S.ArrowUniform/>
            </S.BoxTextUniform>
          </S.ColumnTextUniform>
          <S.ColumnSlideUniform>
            <S.BoxUniform>
              <S.ContentImages>
                <StaticImage src="../../images/uniforme-2022.png" alt="Modelo do uniforme da equipe em 2022"/>
              </S.ContentImages>
              <h1>Uniforme</h1>
              <h2>2022</h2>
            </S.BoxUniform>
          </S.ColumnSlideUniform>
          <S.ColumnSlideStructure>
            <S.BoxStructure>
              <S.BoxImageStructure onClick={() => setShowModal(true)}>
                <StaticImage src="../../images/structure.png" alt="Imagem da estrutura completa da Rosana Merino Sports"/>
              </S.BoxImageStructure>
              <h1>Estrutura</h1>
              <h2>RM</h2>
            </S.BoxStructure>
          </S.ColumnSlideStructure>
          <S.ColumnTextStructure>
            <S.BoxTextStructure>
              <h1>Estrutura</h1>
              <h2>
                • 2 carros utilitários e 1 moto para fazer acompanhamento e apoio nos treinos de ciclismo e
                corrida. <br/>
                • 2 tendas personalizadas para montagem de toda estrutura em treinos e provas. <br/>
                • Patinete elétrico para apoio em treinos e competições. <br/>
                • Drone e máquina fotográfica profissional para registros. <br/>
                • Boias de sinalização para treinos em águas abertas e simulados.
              </h2>
              <S.ArrowStructure/>
            </S.BoxTextStructure>
          </S.ColumnTextStructure>
        </S.Content>
        <S.ContainerTestimony>
          <CarouselTestimony testimony={testimony}/>
        </S.ContainerTestimony>
      </S.WrapperArcos>
    </S.Wrapper>
  );
};

export default PlanItems;
